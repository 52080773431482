// import React from 'react';
import axios from 'axios';
import APIServerRoot from '../../../modules/UIComponents/ApiEnvironment.jsx';

//  func SendRepoEmail(e) => {
const SendRepoEmail = function (namearg, emailarg, messagearg) {
  var name = String(namearg);
  var email = String(emailarg);
  var message = 'Repo Lookup: ' + String(messagearg);

  var API_ROOT = APIServerRoot() + '/sendmail';
  axios({
    method: 'POST',
    url: API_ROOT,
    data: {
      name,
      email,
      message,
    },
  })
    .then((response) => {
      if (response.data === 'success') {
        window.gtag('event', 'Page Read', {
          event_category: 'Repo Lookup Email',
          event_label: 'Repo Lookup: ' + message,
        });
      } else if (response.data === 'error') {
        alert('Message failed to send.');
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default SendRepoEmail;
