import React, { useMemo, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useTable } from 'react-table';
import { Table } from 'reactstrap';
import { DraggableTableRow } from './DraggableTableRow.jsx';
import { StaticTableRow } from './StaticTableRow.jsx';
// import styled from 'styled-components';
import { styled as muistyled } from '@mui/system';
// import Alert from '../../modules/Dialog/DialogOk.jsx';

const MuiStyles = muistyled('div')({
  display: 'block',
  maxWidth: '100%',
  tableWrap: {
    display: 'block',
    maxWidth: '100%',
    overflowX: 'scrol',
    overflowY: 'hidden',
    borderBottom: '1px solid black',
  },
  table: {
    width: '100%',
    borderSpacing: 0,
  },
  pagination: {
    padding: '0.5rem',
  },
});

export function RepoTable({ columns, data, setData, Darkmode }) {
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data.map(({ id }) => id), [data]);
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (over != null) {
      if (active.id !== over.id) {
        setData((data) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          return arrayMove(data, oldIndex, newIndex);
        });
      }
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  const handleRemove = (id) => {
    // console.log('handleRemove, id = ', id);
    // items = items.filter((item) => item !== id);
    // console.log('handleRemove = length of items ', items.length);
    if (items.length == 1) {
      alert('Must have at least one Repository');
      // Alert('Foo', 'blorf');
      return;
    }
    data.splice(id, 1);
    setData((items) => items.filter((item) => item !== id));
    // console.log('handleRemove items = ', items);
  };

  const modeSelector = () => {
    // const darkmode = true;
    if (Darkmode) {
      return true;
    } else {
      return false;
    }
  };

  // Render the UI for your table
  return (
    <MuiStyles>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        // handleRemove={handleRemove}
      >
        <Table
          bordered
          striped
          responsive
          dark={modeSelector()}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...restHeaderProps}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...restColumnProps } = column.getHeaderProps();
                    return (
                      <th key={key + 'RT'} {...restColumnProps}>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowsProps } = row.getRowProps();
                return (
                  <DraggableTableRow
                    key={key}
                    row={row}
                    {...restRowsProps}
                    onRemove={handleRemove}
                  />
                );
              })}
            </SortableContext>
          </tbody>
        </Table>
        <DragOverlay>
          {activeId && (
            <table style={{ width: '100%' }}>
              <tbody>
                <StaticTableRow
                  // key={selectedRow.original.id}
                  row={selectedRow}
                />
              </tbody>
            </table>
          )}
        </DragOverlay>
      </DndContext>{' '}
    </MuiStyles>
  );
}
