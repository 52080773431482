import { promiseMiddleware, localStorageMiddleware } from './middleware.jsx';
import { Tuple, configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/auth.jsx';
import commonReducer from './reducers/common.jsx';
import profileReducer from './reducers/profile.jsx';
import notificationsReducer from './reducers/notifications.jsx';
import reposettingsReducer from './reducers/reposettings.jsx';

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    auth: authReducer,
    common: commonReducer,
    profile: profileReducer,
    notifications: notificationsReducer,
    reposettings: reposettingsReducer,
  },
  middleware: () => new Tuple(promiseMiddleware, localStorageMiddleware),
});

export default store;
