import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';

export default function RepoLibraryLookupMenu({ row }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, item) => {
    var r = row.values.RepoUser + '/' + row.values.RepoName;
    if (item == 'repo') {
      window.open('https://www.gitgenius.co/repos/' + r); //, _blank');
      setAnchorEl(false);
    } else if (item == 'github') {
      window.open('https://github.com/' + r); //, _blank');
      setAnchorEl(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ float: 'left' }}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertSharpIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'repo')}>
          Open Repo
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'github')}>
          Open on Github
        </MenuItem>
      </Menu>
    </div>
  );
}
