import React, { useEffect } from 'react';
import agent from '../../agentAxios.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListErrors from '../ListErrors';
import ResetPasswordForm from './ResetPasswordForm.jsx';
import withStyles from '@mui/styles/withStyles';
import loginStyle from './loginStyle.jsx';
import withRouter from '../../routes/withRouter.jsx';
import { Container } from '@mui/material';

import {
  RESETPASSWORD_PAGE_SAVED,
  RESETPASSWORD_PAGE_UNLOADED,
  RESETPASSWORD_PAGE_LOADED,
} from '../../state/constants/actionTypes.jsx';

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (user) =>
    dispatch({
      type: RESETPASSWORD_PAGE_SAVED,
      payload: agent.Auth.save(user),
    }),
  onUnload: () => dispatch({ type: RESETPASSWORD_PAGE_UNLOADED }),
  onLoad: () => dispatch({ type: RESETPASSWORD_PAGE_LOADED }),
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  usedarkMode: state.common.useDarkMode,
  isLoggedIn: state.common.isLoggedIn,
});

const ResetPassword = ({
  currentUser,
  errors,
  onSubmitForm,
  onUnload,
  onLoad,
}) => {
  useEffect(() => {
    onLoad();
    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm" sx={{ paddingTop: '80px', minHeight: '100vh' }}>
      <div className="container">
        <div className="row justify-content-center align-self-center">
          <div className="col-sm-12 col-md-8">
            <h1 className="text-xs-center">Reset Your Password</h1>
            <ListErrors errors={errors} />
            <ResetPasswordForm
              currentUser={currentUser}
              onSubmitForm={onSubmitForm}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

ResetPassword.propTypes = {
  isLoggedIn: PropTypes.bool,
  currentUser: PropTypes.object,
  errors: PropTypes.array,
  onSubmitForm: PropTypes.func,
  onUnload: PropTypes.func,
  onLoad: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(loginStyle)(ResetPassword))
);
