import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import withStyles from '@mui/styles/withStyles';
// import CollectionsPageStyle from './CollectionsPageStyle.jsx';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import {
  COLECTIONSHOME_PAGE_LOADED,
  COLECTIONSHOME_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: COLECTIONSHOME_PAGE_LOADED }),
  onUnload: () => dispatch({ type: COLECTIONSHOME_PAGE_UNLOADED }),
});

// const TColor = '#eee'; // Text Color
const BTColor = '#48a7f2'; // Blue Text Color

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  fontSize: '1.5em',
  color: theme.palette.text.secondary,
}));
const ItemDescription = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  fontSize: '1em',
  color: theme.palette.text.secondary,
}));

const Collections = [
  {
    image: 'github',
    name: 'Github Accelerator',
    description: 'The 2023 Github Accelerator Class',
    url: '/collections/accelerator',
  },
  {
    image: 'aiml',
    name: 'AI / Machine Learning',
    description: 'AI Related Repositories',
    url: '/collections/ai',
  },
  {
    image: 'containers',
    name: 'Containers',
    description: 'Container Related Repositories',
    url: '/collections/containers',
  },
  {
    image: 'containers',
    name: 'Databases',
    description: 'Database Related Repositories',
    url: '/collections/databases',
  },
  {
    image: 'containers',
    name: 'JS Frameworks',
    description: 'JavaScript Frameworks',
    url: '/collections/jsf',
  },
  {
    image: 'containers',
    name: 'More?',
    description: 'Contact us if you would like to see additional collections',
    url: '/contact',
  },
];

const CollectionsHome = ({ onLoad, onUnload }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    window.gtag('event', 'Page About', {
      event_category: 'About Page',
      event_label: 'About Page',
    });

    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  /* eslint react/prop-types: 0 */
  document.title = 'GitGenius - Repository Collections';

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: '20px',
        minHeight: '100vh',
      }}
    >
      <Helmet>
        <title>GitGenius - Collections</title>
        <meta name="description" content="GitGenius - Repository Collections" />
        <link rel="canonical" href="https://www.gitgenius.co/collections" />
      </Helmet>
      <Card
        sx={{
          minWidth: 275,
          borderRadius: '15px',
          margin: '10px',
        }}
      >
        <CardContent style={{ padding: '10%', textAlign: 'center' }}>
          <Typography
            variant="h2"
            lineHeight={'2em'}
            color={BTColor}
            fontWeight="bold"
          >
            <span style={{ color: '#34a853' }}>Git</span>
            <span style={{ color: '#4285f4' }}>Genius</span>
          </Typography>
          <Typography variant="h2" color={BTColor} marginBottom={'20px'}>
            Collections
          </Typography>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign={'center'}
            color={BTColor}
          >
            A few collections of Repositories currently analyzed by GitGenius
          </Typography>
        </CardContent>
      </Card>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Collections.map((x, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Link href={x.url}>
                <Item>{x.name}</Item>
              </Link>
              <ItemDescription>{x.description}</ItemDescription>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsHome);
