import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import APIServerRoot from '../../modules/UIComponents/ApiEnvironment.jsx';
import adminStyle from './adminStyle.jsx';
import { Chip, Container, Divider } from '@mui/material';

// import TimelineIcon from '@mui/icons-material/Timeline';

import {
  // ADMINMAIN,
  ADMINMAIN_PAGE_LOADED,
  ADMINMAIN_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';

import UpdateInterval from './Interval.jsx';
// import AdminUserTable from './adminUserTable.jsx';
import AdminMetricGrid from './adminMetricGrid.jsx';

// import { ApiCallTypeTime } from './DashboardAPICalls.jsx';

const mapStateToProps = (state) => ({
  ...state.auth,
  isLoggedIn: state.common.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: ADMINMAIN_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ADMINMAIN_PAGE_UNLOADED }),
});

// var API_DETAILS = APIServerRoot() + '/admin/adminmetric';

// async function ApiCallTypeTime(type, time) {
//   // const [userlasthour, setUsersLastHour] = useState(0);
//   // useEffect(() => {
//   // let abortController = new AbortController();
//   console.log('Type, Time =', type, time);
//   // setUsersLastHour(100);
//   var post_data = `{"Type": "` + type + `", "Time": "` + time + `"}`;

//   return axios.post(API_DETAILS, { post_data }).then((response) => {
//     console.log(response.data);
//   });
// }

function AdminMain() {
  const [totalusers, setTotalUsers] = useState();
  const [totalorgs, setTotalOrgs] = useState();
  const [total404, setTotal404] = useState();
  const [userlasthour, setUsersLastHour] = useState();
  const [userlastday, setUsersLastDay] = useState();
  const [userlastmin, setUsersLastMin] = useState();
  const [totalrepos, setTotalRepos] = useState();
  const [updateinterval, setUpdateInterval] = useState(0);

  // const [usertable, setUserTable] = useState();
  const [numofdbusers, setNumOfDBUsers] = useState();
  const [numunitialized, setUninitialized] = useState();

  // var foo = ApiCallTypeTime('User', 'LastDay');
  // console.log('foo = ', foo);
  // let isMounted = false; // note mutable flag
  // const isMounted = useRef(false);
  var API_DETAILS = APIServerRoot() + '/admin/adminmetric';

  // const { classes } = props;
  // const isLoggedIn = useSelector((state) => state.common.isLoggedIn);

  // let navigate = useNavigate();

  document.title = 'GitGenius - Admin Overview';

  const dispatch = useDispatch();
  // formally componentDidMount and componentWillUnmount dispatches now user useEffect
  useEffect(() => {
    dispatch({ type: ADMINMAIN_PAGE_LOADED });
    return () => {
      dispatch({ type: ADMINMAIN_PAGE_UNLOADED });
    };
  }, []);

  // https://coder.earth/post/react-hooks-oops-part-2-effect-runs-multiple-times-with-the-same-dependencies/
  // https://onebite.dev/call-method-only-once-in-useeffect-react/

  useEffect(() => {
    let abortController = new AbortController();
    // isMounted = true; // note mutable flag
    var post_data = `{"Type": "User", "Time": "All"}`;
    // console.log('post_data = ', post_data);
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      // if (isMounted.current) {
      setTotalUsers(axiosresponse.data);
      // isMounted.current = true;
      // }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, []);

  // All Organization
  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "Organization", "Time": "All"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setTotalOrgs(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, []);

  // All 404 Cancelled
  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "404 Cancelled", "Time": "All"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setTotal404(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, []);
  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "User", "Time": "LastDay"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setUsersLastDay(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [updateinterval]);

  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "User", "Time": "LastHour"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setUsersLastHour(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [updateinterval]);

  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "Repo", "Time": "All"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setTotalRepos(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, []); //   }, [totalrepos]);

  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "User", "Time": "Uninitialized"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setUninitialized(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [updateinterval]); //   }, [numunitialized]);

  useEffect(() => {
    let abortController = new AbortController();
    var post_data = `{"Type": "User", "Time": "LastMin"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setUsersLastMin(axiosresponse.data);
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [updateinterval]);

  useEffect(() => {
    let abortController = new AbortController();
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: APIServerRoot() + '/admin/getdbusers',
      });
      // setUserTable(axiosresponse.data.user);
      // console.log('In useEffect ', axiosresponse.data.user.length);
      setNumOfDBUsers(axiosresponse.data.user.length);
    };
    doFetch();
    return () => {
      // console.log('abort controller');
      abortController.abort();
    };
  }, []);

  return (
    <Container
      sx={{
        paddingTop: '80px',
        minHeight: '100vh',
      }}
    >
      <h2 className="text-xs-center"> Admin Metrics</h2>
      <UpdateInterval
        setUpdateInterval={setUpdateInterval}
        updateinterval={updateinterval}
      />
      <Divider sx={{ marginTop: '40px', marginBottom: '20px' }}>
        <Chip label="METRICS" variant="outlined" />
      </Divider>
      <AdminMetricGrid
        totalrepos={totalrepos}
        totalusers={totalusers}
        totalorgs={totalorgs}
        total404={total404}
        userlastmin={userlastmin}
        userlasthour={userlasthour}
        userlastday={userlastday}
        numofdbusers={numofdbusers}
        numunitialized={numunitialized}
      />
      <Divider sx={{ marginTop: '40px', marginBottom: '20px' }}>
        <Chip label="USER DATABASE" variant="outlined" />
      </Divider>

      {/* <AdminUserTable usertable={usertable} /> */}
    </Container>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminStyle)(AdminMain));
