import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import overviewStyle from './stargazeroverviewStyle.jsx';
import StarRepoTable from '../../modules/ReactTableFunctions/StarRepoTable';
// import Footer from '../../modules/Footer/Footer.jsx';
import Container from '@mui/material/Container';
import withRouter from '../../routes/withRouter.jsx';

import {
  STAROVERVIEW_PAGE_LOADED,
  STAROVERVIEW_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';

const OverviewStarGazers = ({ currentUser, onLoad, onUnload }) => {
  useEffect(() => {
    onLoad();
    document.title = 'GitGenius - Repository Overview';
    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  const getRepos = (RepoData) => {
    const repolist = [];
    const count = RepoData.length;
    const repos = Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `repo-${k}`,
      user: `${RepoData[k].user}`,
    }));
    repos.forEach((r, index) => {
      r.reponames = RepoData[index].reponames.map((x) => x);
      r.reponames.forEach((name) => {
        var temprepo = r.user + '/' + name;
        repolist.push({ Name: temprepo });
      });
    });
    return repolist;
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  const repolist = getRepos(JSON.parse(currentUser.Repolist));

  return (
    <Container
      sx={{
        paddingLeft: { lg: '340px' },
        paddingTop: '80px',
        minHeight: '100vh',
      }}
    >
      <h1 className="text-xs-center">Stargazer Overview</h1>
      <Typography paragraph>
        Overview of selected ({repolist.length}) repositories
      </Typography>
      <StarRepoTable
        Repolist={repolist}
        Darkmode={currentUser.darkmode}
        List={'staroverview'}
      />
      <Typography paragraph>
        <li>Click on column titles to sort</li>
        <li>Will auto refresh every 5 minutes</li>
        <li>Time above is hours and minutes since midnight UTC</li>
      </Typography>
      {/* <Footer /> */}
    </Container>
  );
};

OverviewStarGazers.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  currentUser: PropTypes.object,
  onLoad: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: STAROVERVIEW_PAGE_LOADED }),
  onUnload: () => dispatch({ type: STAROVERVIEW_PAGE_UNLOADED }),
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(overviewStyle)(OverviewStarGazers))
);
