import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import { newRepo } from './HelperFunctions/GetRepoData';
import CreateUniqueKey from './HelperFunctions/CreateUniqueKey.jsx';
// import axios from 'axios';
import IsRepoInList from './HelperFunctions/IsRepoInList.jsx';

function prepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export default function AddRepoDialog({ data, setData }) {
  const [open, setOpen] = React.useState(false);
  const [repovalue, setRepoValue] = React.useState('');
  const [repoerror, setRepoError] = React.useState('');
  const [repofound, setRepoFound] = React.useState(false);

  const handleClickOpen = () => {
    setRepoValue('');
    setRepoError('');
    setRepoFound(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Implementation of setTextValue method
  const setTextValue = (event) => {
    setRepoError(false);
    setRepoValue(event.target.value);
    // this.setState({ textValue: event.target.value });
  };
  const handleAdd = () => {
    var inlist = IsRepoInList(data, repovalue);
    // console.log('repovalue = ', repovalue);
    // console.log('data = ', data);
    // var inlist = IsRepoInList(data, repovalue);
    // console.log('in list = ', IsRepoInList(data, repovalue));
    if (inlist) {
      setRepoError('Already in your list of repositories');
    } else {
      var repo = 'https://api.github.com/repos/' + repovalue.toLowerCase();
      fetch(repo)
        .then((response) => response.json())
        .then((response) => {
          let repolowercase = response.full_name.toLowerCase();
          if (repovalue.toLowerCase() == repolowercase) {
            setRepoFound(true);
            setOpen(false);
            var splittable = repo.split('/');
            var ukey = CreateUniqueKey(data);
            var newrepo = newRepo(ukey, splittable[4], splittable[5]);
            setData(prepend(newrepo, data));
          } else {
            setRepoError(
              'Repository has new name - ' + response.data.full_name
            );
            setRepoValue(response.data.full_name);
          }
        })
        .catch(() => {
          // console.error('There was an error!');
          setRepoError('Repository not found');
          // console.clear();
          return;
        });
    }
  };
  return (
    <div>
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '3.5em',
          marginBottom: '3em',
        }}
        variant="outlined"
        onClick={handleClickOpen}
        className="settingButton"
      >
        Add New Repository
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Repository</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new repository in the format of user_name/repository_name
          </DialogContentText>
          <DialogContentText>(i.e. kubernetes/kubernetes)</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Repository"
            type="email"
            fullWidth
            variant="standard"
            onChange={setTextValue}
          />
          {repoerror && (
            <Alert severity="error">
              {repoerror} — <strong>please try again.</strong>
            </Alert>
          )}
          {repofound && (
            <Alert severity="success">
              Repository <strong>verified</strong>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
