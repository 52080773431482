import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import ContactUsStyle from './ContactUsStyle.jsx';
import { Helmet } from 'react-helmet-async';
import SectionContact from '../Home/SectionContact.jsx';
import { Container, Paper, Grid, styled, Typography } from '@mui/material';

import {
  CONTACTUS_PAGE_LOADED,
  CONTACTUS_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});
const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: CONTACTUS_PAGE_LOADED }),
  onUnload: () => dispatch({ type: CONTACTUS_PAGE_UNLOADED }),
});

const ContactUsPage = ({ classes, onLoad, onUnload }) => {
  useEffect(() => {
    document.title = 'GitGenius - Contact Us';
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    // ReactGA4.event({
    //   category: 'User',
    //   action: 'Contact Us Page',
    // });

    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  const BTColor = '#48a7f2'; // Blue Text Color

  const Item = styled(Paper)(() => ({
    padding: 8,
    textAlign: 'center',
    borderRadius: '15px',
  }));

  return (
    <Container
      sx={{ paddingTop: '80px', minHeight: '100vh', background: 'none' }}
    >
      <Helmet>
        <title>GitGenius - Contact Us</title>
        <meta
          name="description"
          content="GitGenius Contact Us with any questions or comments"
        />
        <link rel="canonical" href="https://www.gitgenius.co/contact" />
      </Helmet>

      <h2 className={classes.h2} style={{ marginBottom: '2em' }}>
        Contact Us
      </h2>
      <Grid container spacing={2} alignItems="stretch" sx={{ padding: '10px' }}>
        <Grid item xs={12} style={{ backgroundColor: '#272727' }}>
          <Item
            elevation={3}
            style={{ padding: '5%', backgroundColor: '#272727' }}
          >
            <Typography variant="h5" component="div" color={BTColor}>
              Let&apos;s get this conversation started. Tell us a bit about
              yourself, and we will get in touch as soon as we can.
            </Typography>
          </Item>
          <SectionContact />
        </Grid>
      </Grid>
    </Container>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ContactUsStyle)(ContactUsPage));
