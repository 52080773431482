import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import footerStyle from './footerStyle.jsx';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer(props) {
  // getState();
  // console.log('Footer ', draweropen);
  const { children, classes, theme, big, className } = props;
  const themeType =
    theme === 'transparent' || theme === undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined,
  });

  return (
    <footer className={footerClasses}>
      <div
        className={classes.container}
        style={{ marginTop: '40px', fontSize: '0.8em', color: 'white' }}
      >
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          ' '
        )}

        <Grid
          container
          spacing={2}
          sx={{
            paddingLeft: { lg: '340px' },
          }}
        >
          <Grid item xs={3}>
            <Box>
              <Link to="/terms_of_service">Terms of Service</Link>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Link to="/cookie_policy">Cookie Policy </Link>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Link to="/privacy_policy">Privacy Policy</Link>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Link to="/contact">Contact Us</Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                textAlign: 'right',
              }}
            >
              {' '}
              <Link to="/">GitGenius</Link>
              {'  '}
              &copy; {1900 + new Date().getYear()}
            </Box>
          </Grid>
        </Grid>
      </div>
      <div className={classes.clearFix} />
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(['dark', 'white', 'transparent']),
  big: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
