import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
// import Footer from '../../modules/Footer/Footer.jsx';
import PolicyPageStyle from '../Policies/PolicyPageStyle.jsx';
import { Helmet } from 'react-helmet-async';
import { CircularProgress, Container, Divider, Grid } from '@mui/material';
import RepoCheckForm from './RepoCheckForm.jsx';
import RepoLiookupLibaryTable from '../../../modules/ReactTableFunctions/RepoLiookupLibaryTable';
import axios from 'axios';
import APIServerRoot from '../../../modules/UIComponents/ApiEnvironment.jsx';

import {
  LOOKUPREPO_PAGE_LOADED,
  LOOKUPREPO_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});
const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: LOOKUPREPO_PAGE_LOADED }),
  onUnload: () => dispatch({ type: LOOKUPREPO_PAGE_UNLOADED }),
});

function LookupRepo(props) {
  const { classes } = props;
  const [isLoading, setLoading] = useState(true);
  const [libraryList, setLibraryRepoList] = useState();
  const [RepoToLookup, setRepoToLookUp] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    props.onLoad();
    window.gtag('event', 'Page Read', {
      event_category: 'Repository Lookup',
      event_label: 'Repository Lookup',
    });
    // let abortController = new AbortController();
    var API_DETAILS = APIServerRoot() + '/search/getrepoallnames';
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
      });
      setLoading(false); //stop loading when data is fetched
      setLibraryRepoList(axiosresponse.data);
    };
    doFetch();
    return () => {
      // console.log('abort controller');
      props.onUnload();
      // abortController.abort();
    };
  }, []);

  document.title = 'GitGenius - Repository Lookup';

  if (isLoading) {
    return (
      <Container
        sx={{
          paddingTop: '80px',
          minHeight: '100vh',
          paddingLeft: { lg: '340px' },
        }}
      >
        <div className={classes.toolbar} />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <h2 className="western">Lookup a Repository</h2>
          <div style={{ textAlign: 'center' }}>
            <p>Loading Repository Library...</p>
            <CircularProgress />
          </div>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container
        maxWidth="false"
        sx={{
          paddingTop: '80px',
          minHeight: '100vh',
          paddingLeft: { lg: '340px' },
        }}
      >
        <Helmet>
          <title>GitGenius - Repository Lookup</title>
          <meta
            name="description"
            content="Look up a repository, confirm it exists, and if it has been added to GitGenius"
          />
          <link rel="canonical" href="https://www.gitgenius.co/lookuprepo" />
        </Helmet>
        <div className={classes.toolbar} />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <h1 className="western">Lookup a Repository</h1>
          <h6>
            Look up a repository, confirm it exists, and if it has been added to
            GitGenius
          </h6>
          <RepoCheckForm
            RepoToLookup={RepoToLookup}
            setRepoToLookUp={setRepoToLookUp}
          />
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <RepoLiookupLibaryTable
          libraryList={libraryList}
          setRepoToLookUp={setRepoToLookUp}
        />
      </Container>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(PolicyPageStyle)(LookupRepo));
