import { Link } from 'react-router-dom';
// import ListErrors from '../ListErrors';
import React from 'react';
import agent from '../../agentAxios.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import CustomInput from '../../modules/CustomInput/CustomInput.jsx';
import Button from '../../modules/CustomButtons/Button.jsx';
import withStyles from '@mui/styles/withStyles';
import loginStyle from './loginStyle.jsx';
import Paper from '@mui/material/Paper';
import withRouter from '../../routes/withRouter.jsx';
import {
  UPDATE_FIELD_AUTH,
  TOKENVERIFY,
  TOKENVERIFY_PAGE_LOADED,
  TOKENVERIFY_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  ...state.auth,
  isLoggedIn: state.common.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onLoad: () =>
    dispatch({
      type: TOKENVERIFY,
      payload: agent.Auth.resetlink(window.location.href),
    }),
  onUnload: () => dispatch({ type: TOKENVERIFY_PAGE_UNLOADED }),
  onVerified: () => dispatch({ type: TOKENVERIFY_PAGE_LOADED }),
});

class VerifyResetLink extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
    };
    // console.log('here in VerifyResetLink');
    this.changeEmail = (ev) => this.props.onChangeEmail(ev.target.value);
    // this.changePassword = (ev) => this.props.onChangePassword(ev.target.value);
    this.submitForm = (email, password) => (ev) => {
      ev.preventDefault();
      this.props.onSubmit(email, password);
    };
  }

  componentDidMount() {
    this.props.onLoad();
  }

  shouldComponentUpdate() {
    if (localStorage.getItem('jwt')) {
      this.props.router.navigate('/resetpassword');
    }
    return true;
  }

  componentWillUnmount() {
    this.props.onUnload();
  }
  render() {
    const { classes } = this.props;
    document.title = 'GitGenius - Reset Password';
    if (!this.props.currentUser) {
      return (
        <div className={classes.root}>
          <div className={classes.container}>
            <div>Loading...</div>
          </div>
        </div>
      );
    } else {
      this.props.router.navigate('/resetpassword');
    }
    // console.log('Current User = ', this.props.currentUser);
    const email = this.props.email || '';
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <h1>Account Recovery</h1>
                <p className="text-xs-center">
                  <Link to="/register">
                    Enter your email address to receive a recovery link...
                  </Link>
                </p>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <form onSubmit={this.submitForm(email)}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sx={{ maxWidth: 400 }}>
                      <Paper className={classes.paper}>
                        <CustomInput
                          type="email"
                          labelText="Email"
                          autoComplete="email"
                          value={email}
                          onChange={this.changeEmail}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{ maxWidth: 400 }}>
                      <Paper className={classes.paper}>
                        <Button
                          className="settingButton"
                          color="primary"
                          type="submit"
                          disabled={this.props.inProgress}
                        >
                          Submit
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

VerifyResetLink.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(loginStyle)(VerifyResetLink))
);
