import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  LinearProgress,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import APIServerRoot from '../../../modules/UIComponents/ApiEnvironment.jsx';
import { format } from 'date-fns';
import RepoGetCounts from '../../../modules/RepoUtilities/RepoGetCounts.jsx';
import RepoGetDetails from '../../../modules/RepoUtilities/RepoGetDetails.jsx';
import SendRepoEmail from './SendRepoEmail.jsx';

const YupValidation = yup.object().shape({
  repository: yup.string().required('Required !'),
});

const RepoCheckForm = (props) => {
  // console.log('RepoCheckForm props = ', props.RepoToLookup);
  const [repoerror, setRepoError] = useState(false);
  const [repofound, setRepoFound] = useState('');
  const [repoindexed, setRepoIndexed] = useState(false);
  const [repoaddedon, setRepoAddedOn] = useState('');
  const [initValue, setInitValue] = useState(props.RepoToLookup);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setInitValue(props.RepoToLookup);
    setRepoError('');
    setRepoFound('');
    setRepoIndexed(false);
  }, [props.RepoToLookup]);

  const handleSubmit = (values) => {
    setSubmitting(true);
    setRepoError(false);
    setRepoFound('');
    setRepoIndexed(false);

    // Check for Repository on github
    let abortController = new AbortController();
    var post_data = `[{"Name":"` + values.repository.toLowerCase() + `"}]`;
    const doinitFetch = async () => {
      const initresponse = await axios({
        method: 'POST',
        url: APIServerRoot() + '/search/getgithubrepo',
        data: { post_data },
      });

      if (!initresponse.data.full_name) {
        setRepoError(true);
      } else {
        let repolowercase = initresponse.data.full_name.toLowerCase();
        if (values.repository == repolowercase) {
          setRepoFound('Repository found in GitHub.com');
        } else {
          setRepoFound('Repository has new name - ' + repolowercase);
        }
      }
    };
    doinitFetch();

    // Check for Repository in gitgenius.co

    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: APIServerRoot() + '/search/getrepodetails',
        data: { post_data },
      });
      if (axiosresponse.data != 'Not found') {
        setRepoIndexed(true);
        var date = new Date(axiosresponse.data.escreated_at);
        var formattedDate = format(date, 'MMMM do, yyyy H:mma');
        setRepoAddedOn(formattedDate);
      } else {
        setRepoError(true);
        SendRepoEmail(
          'system',
          'support@gitgenius.co',
          values.repository.toLowerCase()
        );
      }
    };
    doFetch();
    setSubmitting(false);

    return () => {
      abortController.abort();
    };
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {/* <Grid item sm={3} xs={false}></Grid> */}
      {isSubmitting && <LinearProgress />}

      <Paper>
        <Box m={0} p={3} sx={{ minWidth: '350px', maxWidth: '600px' }}>
          <Typography variant="h6">Enter Repository Name:</Typography>
          <Formik
            initialValues={{ repository: initValue }}
            enableReinitialize
            validationSchema={YupValidation}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const { repository } = props.values;
              return (
                <Form>
                  <TextField
                    fullWidth
                    label="Enter user_name/repository_name"
                    name="repository"
                    variant="outlined"
                    margin="dense"
                    value={repository}
                    // onChange={(e) => props.setRepoToLookUp(e.target.value)}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    helperText={<ErrorMessage name="name" />}
                    error={props.errors.name && props.touched.name}
                    required
                  />
                  <div>{repoerror}</div>
                  {repoerror && !repofound && (
                    <Alert severity="error">
                      Repository not found on GitHub.com — please try again.
                    </Alert>
                  )}
                  {repofound != '' && (
                    <Alert severity="success">
                      <strong>{repofound}</strong>
                    </Alert>
                  )}
                  {repoerror && repofound && !repoindexed && (
                    <div>
                      <Alert severity="info">
                        <strong>Repository not yet added to GitGenius</strong>
                        <RepoGetCounts reponame={repository} />
                      </Alert>
                    </div>
                  )}
                  {repoindexed && (
                    <div>
                      <Alert severity="success">
                        <strong>
                          <Link
                            href={
                              'https://www.gitgenius.co/repos/' + repository
                            }
                          >
                            Added to GitGenius on {repoaddedon}
                          </Link>
                        </strong>
                      </Alert>
                      <RepoGetDetails reponame={repository} />
                    </div>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                    sx={{ marginTop: '10px' }}
                  >
                    Lookup
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
      <Grid item sm={3} xs={false}></Grid>
    </Grid>
  );
};

export default RepoCheckForm;
