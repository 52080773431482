import React, { forwardRef, useState } from 'react';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
// import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import RepoAddSelectedToData from '../../modules/ReactTableFunctions/RepoAddSelectedToData.jsx';

// const Styles = styled('div')(({ theme }) => ({
//   table: {
//     borderSpacing: 0,
//     width: '100%',
//   },

//   th: {
//     margin: 0,
//     fontSize: '0.9em',
//     padding: '0.5rem',
//     '&:(:last-child)': {
//       borderBottom: 0,
//       borderRight: 0,
//     },
//   },
//   td: {
//     margin: 0,
//     backgroundColor:
//       theme.palette.mode === 'dark'
//         ? 'rgba(255, 255, 255, .05)'
//         : 'rgba(0, 0, 0, .03)',
//     padding: '0.5rem',
//     fontSize: '0.9em',
//     borderBottom: '1px solid blacl',
//     // borderRight: '1px solid black',
//     '&:(:last-child)': {
//       borderBottom: 0,
//       borderRight: 0,
//     },
//   },
//   tr: {
//     // lineHeight: '60px',
//     backgroundColor:
//       theme.palette.mode === 'dark'
//         ? 'rgba(255, 255, 255, .05)'
//         : 'rgba(0, 0, 0, .03)',
//   },
//   pagecontrols: {
//     padding: '0.5rem',
//   },
//   spantext: {
//     fontSize: '0.5em',
//   },
// }));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

function Table({ columns, data, repodata }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };
  const [response, setResponse] = useState('');

  // Render the UI for your table
  return (
    <div>
      <table {...getTableProps()} style={{ width: '100%' }}>
        <thead style={{ margin: '0', fontSize: '0.9em', padding: '0.5rem' }}>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr {...restHeaderProps} key={key}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumnProps}>
                      <div {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {generateSortingIndicator(column)}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={i}
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                }}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      {...cell.getCellProps((props) => [
                        props,
                        {
                          style: {
                            maxWidth: 100,
                            wordWrap: 'break-word',
                          },
                        },
                      ])}
                      key={row.id + '-' + i}
                      // style={{ height: '10px' }}
                    >
                      {cell.render('Cell')}{' '}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagecontrols">
        <div>
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardDoubleArrowLeftRoundedIcon />
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardArrowLeftRoundedIcon />
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardArrowRightRoundedIcon />
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardDoubleArrowRightRoundedIcon />
          </Button>
          <Box component="span" sx={{ color: 'primary.main' }}>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1, color: 'primary.main', fontSize: '0.9em' }}>
          <Grid
            container
            alignItems="center"
            justify="center"
            spacing={2}
            // style={{ border: 'solid 5px', height: '60%' }}
          >
            <Grid item xs={3}>
              <TextField
                id="pagesize"
                select
                label="# of Repos"
                size="small"
                fullWidth={true}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                // helperText="Repos to Show"
                variant="filled"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                // fullWidth="false"
                onClick={() => {
                  RepoAddSelectedToData(
                    repodata,
                    selectedFlatRows,
                    setResponse
                  );
                  toggleAllRowsSelected(false);
                }}
                disabled={!canNextPage}
              >
                Add Selected Repos
              </Button>{' '}
            </Grid>
            <Grid item xs={3}>
              {response}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

function RepoLibraryTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Repositories in Libraryasdf',
        columns: [
          {
            Header: 'User',
            accessor: 'RepoUser',
          },
          {
            Header: 'Name',
            accessor: 'RepoName',
          },
          // {
          //   Header: 'Added',
          //   accessor: 'DateAdded',
          // },
        ],
      },
    ],
    []
  );

  // var theme = useTheme();

  if (props.length != 0) {
    // console.log('Styles = ', Styles);

    return (
      // <Styles theme={theme}>
      <Table columns={columns} data={props.libraryList} repodata={props} />
      // </Styles>
    );
  }
}

export default RepoLibraryTable;
