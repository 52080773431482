import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import repodetailsStyle from './repodetailsStyle.jsx';
import axios from 'axios';
import APIServerRoot from '../../modules/UIComponents/ApiEnvironment.jsx';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ReChartTopSeen from '../../modules/ReactCharts/reChartsTopSeen.jsx';

function RepoTopSeen(props) {
  let isMounted = false; // note mutable flag
  var API_TOPSEEN = APIServerRoot() + '/search/getrepotopseen';
  let reponame = props.Reponame;
  // reponame = reponame.substring(1);
  const [topseen, setTopSeen] = useState([]);

  const [repotype, setRepotype] = useState('stars');

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setRepotype(newAlignment);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    isMounted = true; // note mutable flag

    var post_data =
      `{"Name":"` +
      reponame.toLowerCase() +
      `", "RType":"` +
      repotype +
      `", "Start":"` +
      1 +
      `", "End":"` +
      100 +
      `"}`;
    // var post_data = `[{"Name":"` + reponame.toLowerCase() + `"}]`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_TOPSEEN,
        data: { post_data },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      });
      if (isMounted) {
        setTopSeen(axiosresponse.data);
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [reponame, repotype]);
  if (topseen == 'error, SeenCounts=0') {
    return (
      <div>
        <h3>Top Repositories</h3>
        <p>
          Followed by {repotype} of {reponame}
        </p>
        <ToggleButtonGroup
          color="primary"
          value={repotype}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="stars">Stargazers</ToggleButton>
          <ToggleButton value="subs">Subscribers</ToggleButton>
        </ToggleButtonGroup>
        <div style={{ height: '500px' }}>
          <p>{reponame} initializing...</p>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3>Top 100 Repositories</h3>
        <p>
          {repotype == 'subs' && 'Followed by subcribers '}
          {repotype == 'stars' && 'Followed by stargazers '}
          of {reponame}
        </p>
        <ToggleButtonGroup
          color="primary"
          value={repotype}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="stars">Stargazers</ToggleButton>
          <ToggleButton value="subs">Subscribers</ToggleButton>
        </ToggleButtonGroup>
        <ReChartTopSeen RepoList={topseen.RepoList} Height="4000px" />
      </div>
    );
  }
}

export default connect()(withStyles(repodetailsStyle)(RepoTopSeen));
