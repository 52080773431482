import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RepoAccordianDetails from './RepoAccordianDetails.jsx';
// full_name.toLowerCase()
export default function RepoAccordian({ repoinfo }) {
  // console.log('RepoAccordian props = ', repoinfo);
  if (repoinfo.reposummary) {
    if (repoinfo.repoerror) {
      return (
        <div style={{ marginTop: '1em' }}>
          <AccordionDetails>
            <Typography>Not Found on Github or GitGenius</Typography>
          </AccordionDetails>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '1em' }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>
                Repository Detail for {repoinfo.reposummary.full_name}
              </Typography>
            </AccordionSummary>
            <RepoAccordianDetails
              repoinfo={{
                reposummary: repoinfo.reposummary,
                repoerror: repoinfo.repoerror,
                repoindexed: repoinfo.repoindexed,
              }}
            />
          </Accordion>
        </div>
      );
    }
  }
}
