import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  createTheme,
  Grid,
  Link,
  Paper,
  styled,
  ThemeProvider,
} from '@mui/material';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
// import Background from '../../assets/img/homepagebg.jpg';
import HomeStars from '../../../assets/img/homestarsdark.png';
import HomeSubs from '../../../assets/img/homesubsdark.png';
import TopSeen from '../../../assets/img/top100k8s.webp';
// import SectionContact from './SectionContactNew.jsx';
import ProductDetails from './ProductDetails.jsx';
import ProductDetailsMobile from './ProductDetailsMobile.jsx';
import SectionContact from './SectionContact.jsx';
import { Helmet } from 'react-helmet-async';
import RepoAutoComplete from '../../../modules/RepoSummaryAutoComplete/RepoAutoComplete.jsx';
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: HOME_PAGE_LOADED }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
});
// Pallette
//  - 48a7f2  Blue [used in Graphs]
//  - FD704B  Orange [used in Graphs]
//  - bffaff  light blue
//  - 132f4c  dark blue
//  - ffb703  yellow
//  - fd6f4b  orange

const TColor = '#eee'; // Text Color
const BTColor = '#48a7f2'; // Blue Text Color
const BColor = '#203a55'; // Background Color
const Item = styled(Paper)(() => ({
  padding: 8,
  textAlign: 'center',
  borderRadius: '15px',
}));

const font = "'Noto Sans', sans-serif";
const themelight = createTheme({
  typography: {
    fontFamily: font,
  },
  palette: {
    mode: 'dark',
  },
});

// h1 - 6rem, h2 - 3.75rem,  h4 - 2.125rem, h5 - 1.5rem
themelight.typography.h1 = {
  fontSize: '6rem',
  // '@media (min-width:600px)': {
  //   fontSize: '1.5rem',
  // },
  [themelight.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
};
themelight.typography.h2 = {
  fontSize: '3.75rem',
  [themelight.breakpoints.down('md')]: {
    fontSize: '1.75rem',
  },
};
themelight.typography.h3 = {
  fontSize: '3rem',
  [themelight.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
};
themelight.typography.h4 = {
  fontSize: '2.125rem',
  [themelight.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  },
};

themelight.typography.h5 = {
  fontSize: '1.5rem',
  [themelight.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
};
themelight.typography.h6 = {
  fontSize: '1rem',
  [themelight.breakpoints.down('md')]: {
    fontSize: '0.8rem',
  },
};

function Home(props) {
  useEffect(() => {
    props.onLoad();
  }, []);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 900;
  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);
  document.title = 'GitGenius - Tools to analyze Repositories';

  return (
    <ThemeProvider theme={themelight}>
      <CssBaseline />
      <Helmet>
        <title>
          GitGenius - Analyze your Repos, Followers, Stargazers, Starred Users,
          as well as your Subscribers
        </title>

        <meta
          property="og:title"
          content="GitGenius - Analyze your Repos, Followers, Stargazers, Starred
          Users, as well as your Subscribers"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.gitgenius.co" />
        <link rel="canonical" href="https://www.gitgenius.co" />
        <meta name="GitGenius" value="GitGenius" />
        <meta
          name="description"
          content="GitGenius provides tools to analyze who is viewing your repositories, 
          and analytics to better understand your community. We do this by constantly monitoring and analyzing repositories which you are interested in. We aggregate data, apply analytics, show historical trends, and more."
        />
        <meta
          property="og:description"
          content="GitGenius provides tools to analyze who is 
        viewing your repositories, and analytics to better understand your community. We do this by constantly monitoring and analyzing repositories which you are interested in. We aggregate data, apply analytics, show historical trends, and more."
        ></meta>

        <meta
          name="description"
          content="Analyze your Repos, Followers, Stargazers, Starred Users, as
            well as your Subscribers, understand more about your audience. Show number 
            of users added per day, and view other repositories those users also follow"
        />
      </Helmet>
      <div
        style={{
          padding: '0px',
          backgroundColor: '#272727',
        }}
      >
        <div style={{ minHeight: '70px' }}></div>
        <Card
          sx={{
            backgroundColor: '#272727',
            margin: '10px',
          }}
        >
          <CardContent style={{ padding: '10%', textAlign: 'center' }}>
            {/* <h1
              style={{
                lineHeight: '3em',
                fontSize: '4em',
                fontWeight: 'bold',
                color: BTColor,
              }}
            >
              <span style={{ color: '#34a853' }}>Git</span>
              <span style={{ color: '#4285f4' }}>Genius</span>
            </h1> */}
            <Typography
              variant="h1"
              component="h1"
              lineHeight={'3em'}
              color={BTColor}
              fontWeight="bold"
            >
              <span style={{ color: '#34a853' }}>Git</span>
              <span style={{ color: '#4285f4' }}>Genius</span>
            </Typography>
            <RepoAutoComplete />
          </CardContent>
        </Card>
        <Card
          sx={{
            minWidth: 275,
            // borderRadius: '15px',
            // margin: '10px',
            padding: '5%',
            backgroundColor: '#272727',
          }}
        >
          <Typography
            variant="h5"
            component="div"
            textAlign={'center'}
            color={TColor}
          >
            <span style={{ color: '#34a853' }}>Git</span>
            <span style={{ color: '#4285f4' }}>Genius</span> provides tools to
            analyze who is viewing your repositories and to better understand
            your community. This is done by constantly monitoring and analyzing
            repositories, aggregate data, apply analytics, show historical
            trends, and more.
          </Typography>
        </Card>
        <Card
          sx={{
            minWidth: 275,
            // borderRadius: '15px',
            margin: '10px',
            padding: '5%',
            backgroundColor: BColor,
          }}
        >
          {width < breakpoint ? <ProductDetailsMobile /> : <ProductDetails />}
        </Card>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          sx={{ padding: '10px' }}
        >
          <Grid item xs={12}>
            <Item
              elevation={3}
              style={{ backgroundColor: BColor, padding: '5%' }}
            >
              <div style={{ textAlign: 'center', margin: '10px' }}>
                <Typography variant="h4" color={BTColor}>
                  See a timeline of Subscribers and Stargazers since a
                  repository was created.
                </Typography>
                <Typography
                  variant="h5"
                  color={TColor}
                  marginTop={'20px'}
                  marginBottom={'20px'}
                >
                  Example: Repository details for kubernets/kubernetes
                </Typography>
                <Button variant="contained" size="small">
                  <Link
                    rel="canonical"
                    href="/repos/kubernetes/kubernetes"
                    style={{ color: TColor }}
                  >
                    Click here to see live data
                  </Link>
                </Button>
              </div>

              <CardContent>
                <Typography variant="h5" textAlign={'left'} color={TColor}>
                  Stargazers per day (120 days)
                </Typography>

                <Box
                  component="img"
                  sx={{ width: '100%' }}
                  src={HomeStars}
                  alt="Github stargazers added and deleted over last year"
                ></Box>
                <Typography variant="h5" textAlign={'left'} color={TColor}>
                  Subscribers per day (120 days)
                </Typography>
                <Box
                  component="img"
                  sx={{ width: '100%', backgroundColor: 'blue' }}
                  src={HomeSubs}
                  alt="GitHub subscribers added and deleted over last year"
                ></Box>
                <Typography variant="h6" textAlign={'left'} color="white">
                  <span style={{ fontWeight: 'bold' }}>First Seen</span>= the #
                  of new users added per day,
                </Typography>
                <Typography variant="h6" textAlign={'left'} color="white">
                  <span style={{ fontWeight: 'bold' }}> Last Seen</span> = the #
                  of users that unWatched or unSubscribed per day.
                </Typography>
              </CardContent>
            </Item>{' '}
          </Grid>

          <Grid item xs={12}>
            <Item
              elevation={3}
              style={{ padding: '5%', backgroundColor: '#272727' }}
            >
              <Typography
                lineHeight={'3em'}
                color={BTColor}
                fontWeight="bold"
                variant="h3"
                textAlign={'center'}
              >
                Analytics
              </Typography>
              <Typography
                variant="h5"
                color={'white'}
                textAlign={'center'}
                lineHeight={'1.5em'}
              >
                Once a repository is added to gitgenius.co, all the users are
                continually updated, and analyzed. One such benefit is tracking
                what other repositories are starred, or subcribed to, by every
                user. GitGenius provides an interactive table, for both
                subscribers and stargazers, is continously updated to show the
                top 100 repositories also followed by a repositories users.
                <p></p>
                Below is the list of stargazers for kubernetes. Golang, followed
                by Tensorflow, Moby, and React, are the repositories which are
                of most interest to Kubernetes users.
                <p></p>
                Additional analytics are available to registered users, and
                additional analytics are planned.
              </Typography>
              <CardContent>
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                  }}
                  src={TopSeen}
                  alt="Topp 100 Repositodries followed by stargazers and subscribers"
                ></Box>
                <p style={{ fontSize: '3em', color: 'white' }}>...</p>
                {/* <Item elevation={3}> */}
                <Button variant="contained" size="large">
                  <Link
                    rel="canonical"
                    href="/repos/kubernetes/kubernetes"
                    style={{ color: 'white' }}
                  >
                    Click here to see live data
                  </Link>
                </Button>
                {/* </Item> */}
              </CardContent>
            </Item>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: '#272727' }}>
            <Item
              elevation={3}
              style={{ padding: '5%', backgroundColor: '#272727' }}
            >
              <Typography
                variant="h4"
                component="div"
                textAlign={'center'}
                color={BTColor}
                // backgroundColor={'#272727'}
              >
                Find out more
              </Typography>
              <Typography variant="h5" component="div" color={BTColor}>
                If you would like to learn more, or trial the product, please
                contact us or sign up for an account.
              </Typography>
            </Item>
            <SectionContact />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  isLoggedIn: state.common.isLoggedIn,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
