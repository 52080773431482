import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
//Public Routes
// import HomeOld from '../components/Home-Old/Home.jsx';
import Home from '../components/ExternalPages/Home/Home.jsx';
import Login from '../components/Login/Login.jsx';
import Register from '../components/Register/RegisterFormik.jsx';
import LoginReset from '../components/Login/LoginReset.jsx';
import VerifyResetLink from '../components/Login/VerifyResetlink.jsx';
import PrivacyPolicy from '../components/ExternalPages/Policies/PrivacyPolicy.jsx';
import CookiePolicy from '../components/ExternalPages/Policies/CookiePolicy.jsx';
import TermsOfService from '../components/ExternalPages/Policies/TermsOfService.jsx';
import ContactUs from '../components/ExternalPages/ContactUs/ContatUs.jsx';
//Private routes
import StargazerOverview from '../components/OverviewStargazers/Overview.jsx';
import SubscriberOverview from '../components/OverviewSubscribers/Overview.jsx';
import RepoDetails from '../components/RepoDetails/RepoDetails.jsx';
import Profile from '../components/Profile/Profile.jsx';
import RepoSettings from '../components/RepoSettings/RepoSettings.jsx';
import ResetPassword from '../components/Login/ResetPassword.jsx';
import Notifications from '../components/NotificationsSettings/NotificationsSettings.jsx';
import AdminMain from '../components/Administrator/AdminMain.jsx';
import LookupUser from '../components/ExternalPages/LookupUser/LookupUser.jsx';
import LookupRepo from '../components/ExternalPages/LookupRepo/LookupRepo.jsx';
import About from '../components/ExternalPages/About/About.jsx';
import CollectionsHome from '../components/ExternalPages/Collections/CollectionsHome.jsx';
import CollectionsAI from '../components/ExternalPages/Collections/Pages/CollectionAI.jsx';
import CollectionsGitAccel from '../components/ExternalPages/Collections/Pages/CollectionGitAccel.jsx';
import CollectionsJSF from '../components/ExternalPages/Collections/Pages/CollectionJSF.jsx';
import CollectionContainers from '../components/ExternalPages/Collections/Pages/CollectionContainer.jsx';
import CollectionsDB from '../components/ExternalPages/Collections/Pages/CollectionDBs.jsx';
function PrivateRoute({ children }) {
  const auth = localStorage.getItem('jwt');
  return auth ? children : <Navigate to="/login" />;
}

const RouterList = (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route exact path="/passwordreset" element={<LoginReset />} />
    <Route exact path="/resetlink/*" element={<VerifyResetLink />} />
    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    <Route path="/cookie_policy" element={<CookiePolicy />} />
    <Route path="/terms_of_service" element={<TermsOfService />} />
    <Route path="/contact" element={<ContactUs />} />
    {/* <Route exact path="/samplerepodetail" element={<ExampleRepoDetail />} /> */}
    <Route path="/lookupuser" element={<LookupUser />} />
    <Route path="/lookuprepo" element={<LookupRepo />} />
    <Route path="/about" element={<About />} />
    <Route exact path="/collections" element={<CollectionsHome />} />
    <Route path="/collections/ai" element={<CollectionsAI />} />
    <Route path="/collections/containers" element={<CollectionContainers />} />
    <Route path="/collections/accelerator" element={<CollectionsGitAccel />} />
    <Route path="/collections/jsf" element={<CollectionsJSF />} />
    <Route path="/collections/databases" element={<CollectionsDB />} />
    <Route
      path="/stargazeroverview"
      element={
        <PrivateRoute>
          <StargazerOverview />
        </PrivateRoute>
      }
    />
    <Route
      path="/subscriberoverview"
      element={
        <PrivateRoute>
          <SubscriberOverview />
        </PrivateRoute>
      }
    />
    <Route
      path="/reposettings"
      element={
        <PrivateRoute>
          <RepoSettings />
        </PrivateRoute>
      }
    />
    <Route
      path="/profile"
      element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path="/notifications"
      element={
        <PrivateRoute>
          <Notifications />
        </PrivateRoute>
      }
    />
    <Route
      path="/resetpassword"
      element={
        <PrivateRoute>
          <ResetPassword />
        </PrivateRoute>
      }
    />
    <Route
      path="/repos/*"
      element={
        <PrivateRoute>
          <RepoDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/adminmain"
      element={
        <PrivateRoute>
          <AdminMain />
        </PrivateRoute>
      }
    />
    <Route exact path="/repos/rook/rook" element={<RepoDetails />} />
    <Route exact path="/repos/facebook/react" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubernetes/kubernetes"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/SeleniumHQ/selenium" element={<RepoDetails />} />
    <Route exact path="/repos/kubevirt/kubevirt" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/apache/incubator-kie-drools"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/awslabs/git-secrets" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/coreos/coreos-kubernetes"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/containers/podman" element={<RepoDetails />} />
    <Route exact path="/repos/akamai/boomerang" element={<RepoDetails />} />
    <Route exact path="/repos/cri-o/cri-o" element={<RepoDetails />} />
    <Route exact path="/repos/reduxjs/redux-thunk" element={<RepoDetails />} />
    <Route exact path="/repos/reduxjs/redux" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/reduxjs/redux-toolkit"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/denoland/deno" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/foxlet/macOS-Simple-KVM"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kiali/kiali" element={<RepoDetails />} />
    <Route exact path="/repos/kiali/kiali-ui" element={<RepoDetails />} />
    <Route exact path="/repos/tektoncd/pipeline" element={<RepoDetails />} />
    <Route exact path="/repos/tektoncd/dashboard" element={<RepoDetails />} />
    <Route exact path="/repos/tektoncd/triggers" element={<RepoDetails />} />
    <Route exact path="/repos/tektoncd/cli" element={<RepoDetails />} />
    <Route exact path="/repos/tektoncd/catalog" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/rancher" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/rke" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/rio" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/os" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/k3os" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/wrangler" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/ui" element={<RepoDetails />} />
    <Route exact path="/repos/rancher/fleet" element={<RepoDetails />} />
    <Route exact path="/repos/vmware-tanzu/velero" element={<RepoDetails />} />
    <Route exact path="/repos/vmware-archive/tgik" element={<RepoDetails />} />
    <Route exact path="/repos/ceph/ceph" element={<RepoDetails />} />
    <Route exact path="/repos/ceph/ceph-ansible" element={<RepoDetails />} />
    <Route exact path="/repos/ceph/ceph-ci" element={<RepoDetails />} />
    <Route exact path="/repos/ceph/ceph-deploy" element={<RepoDetails />} />
    <Route exact path="/repos/ceph/ceph-container" element={<RepoDetails />} />
    <Route exact path="/repos/ceph/ceph-client" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/noobaa/noobaa-operator"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/noobaa/noobaa-core" element={<RepoDetails />} />
    <Route exact path="/repos/noobaa/noobaa-mixins" element={<RepoDetails />} />
    <Route exact path="/repos/kedacore/keda" element={<RepoDetails />} />
    <Route exact path="/repos/gluster/glusterfs" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/gluster/gluster-ansible"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/ManageIQ/manageiq" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/ManageIQ/integration_tests"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/ManageIQ/manageiq-automation_engine"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/ManageIQ/manageiq-api"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/ManageIQ/guides" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/ManageIQ/manageiq-ui-classic"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/origin" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openshift/elasticsearch-operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/ansible-service-broker"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/openshift-ansible-contrib"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/openshift-ansible"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/ansible/ansible" element={<RepoDetails />} />
    <Route exact path="/repos/ansible/awx" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/ansible/ansible-examples"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/ansible/ansible-jupyter-kernel"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/minishift/minishift" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/minishift/minishift-addons"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kubernetes/minikube" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubernetes/autoscaler"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kubernetes/community" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubernetes/cloud-provider-openstack"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/kubernetes/cloud-provider-gcp"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kubernetes/dashboard" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubernetes/kube-state-metrics"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kubernetes/client-go" element={<RepoDetails />} />
    <Route exact path="/repos/istio/istio" element={<RepoDetails />} />
    <Route exact path="/repos/istio/api" element={<RepoDetails />} />
    <Route exact path="/repos/istio/community" element={<RepoDetails />} />
    <Route exact path="/repos/quay/quay" element={<RepoDetails />} />
    <Route exact path="/repos/quay/clair" element={<RepoDetails />} />
    <Route exact path="/repos/quay/jwtproxy" element={<RepoDetails />} />
    <Route exact path="/repos/quay/claircore" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/prometheus/prometheus"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/prometheus/alertmanager"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/prometheus/node_exporter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/docker/compose-on-kubernetes"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/docker/compose" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/docker-archive/compose-cli"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/docker/cli" element={<RepoDetails />} />
    <Route exact path="/repos/docker/for-mac" element={<RepoDetails />} />
    <Route exact path="/repos/docker/for-win" element={<RepoDetails />} />
    <Route exact path="/repos/helm/helm" element={<RepoDetails />} />
    <Route exact path="/repos/helm/charts" element={<RepoDetails />} />
    <Route exact path="/repos/helm/monocular" element={<RepoDetails />} />
    <Route exact path="/repos/goharbor/harbor" element={<RepoDetails />} />
    <Route exact path="/repos/goharbor/harbor-helm" element={<RepoDetails />} />
    <Route exact path="/repos/etcd-io/etcd" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/spring-projects/spring-boot"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/spring-projects/spring-framework"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/crc-org/crc" element={<RepoDetails />} />
    <Route exact path="/repos/quarkusio/quarkus" element={<RepoDetails />} />
    <Route exact path="/repos/elastic/kibana" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/elastic/elasticsearch"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/elastic/beats" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/elastic/elasticsearch-js"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/elastic/ansible-elasticsearch"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/elastic/ansible-beats"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/elastic/go-elasticsearch"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/elastic/eui" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/elastic/elasticsearch-hadoop"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/hashicorp/consul" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/hcl" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/terraform" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/nomad" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/packer" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/vault" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/raft" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/vagrant" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/hashicorp/consul-template"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/hashicorp/serf" element={<RepoDetails />} />
    <Route exact path="/repos/hashicorp/boundary" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/hashicorp/terraform-provider-kubernetes"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/hashicorp/consul-helm"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/SeleniumHQ/selenium-ide"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/adium/adium" element={<RepoDetails />} />
    <Route exact path="/repos/grafana/grafana" element={<RepoDetails />} />
    <Route exact path="/repos/vercel/next.js" element={<RepoDetails />} />
    <Route exact path="/repos/vercel/hyper" element={<RepoDetails />} />
    <Route exact path="/repos/vercel/serve" element={<RepoDetails />} />
    <Route exact path="/repos/vercel/micro" element={<RepoDetails />} />
    <Route exact path="/repos/ReactiveX/rxjs" element={<RepoDetails />} />
    <Route exact path="/repos/ReactiveX/RxGo" element={<RepoDetails />} />
    <Route exact path="/repos/h2oai/h2o-3" element={<RepoDetails />} />
    <Route exact path="/repos/h2oai/h2o-tutorials" element={<RepoDetails />} />
    <Route exact path="/repos/jestjs/jest" element={<RepoDetails />} />
    <Route exact path="/repos/facebook/relay" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/facebook/create-react-app"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/facebook/react-devtools"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/facebook/react-native"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/facebook/rocksdb" element={<RepoDetails />} />
    <Route exact path="/repos/gorilla/mux" element={<RepoDetails />} />
    <Route exact path="/repos/gorilla/websocket" element={<RepoDetails />} />
    <Route exact path="/repos/gorilla/handlers" element={<RepoDetails />} />
    <Route exact path="/repos/gorilla/sessions" element={<RepoDetails />} />
    <Route exact path="/repos/gorilla/schema" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/timescale/timescaledb"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/timescale/prometheus-postgresql-adapter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/timescale/timescaledb-tune"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/timescale/pg_prometheus"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/timescale/tsbs" element={<RepoDetails />} />
    <Route exact path="/repos/apple/foundationdb" element={<RepoDetails />} />
    <Route exact path="/repos/knative/serving" element={<RepoDetails />} />
    <Route exact path="/repos/knative/docs" element={<RepoDetails />} />
    <Route exact path="/repos/knative/eventing" element={<RepoDetails />} />
    <Route exact path="/repos/knative/client" element={<RepoDetails />} />
    <Route exact path="/repos/knative/build" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/knative/observability"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/moby/moby" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/openstack" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/nova" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/keystone" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/horizon" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/neutron" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/ironic" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/swift" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openstack/openstack-ansible"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/graphql/graphql-spec" element={<RepoDetails />} />
    <Route exact path="/repos/graphql/graphql-js" element={<RepoDetails />} />
    <Route exact path="/repos/graphql/dataloader" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/apache/incubator-kie-kogito-runtimes"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/apache/incubator-kie-optaplanner"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/projectcontour/contour"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/vmware/photon" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/vmware/container-service-extension"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/vuejs/vue" element={<RepoDetails />} />
    <Route exact path="/repos/angular/angular" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubecost/cost-analyzer-helm-chart"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/kubecost/cost-analyzer"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/kubecost/kubectl-cost"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/opencost/opencost" element={<RepoDetails />} />
    <Route exact path="/repos/kubevirt/user-guide" element={<RepoDetails />} />
    <Route exact path="/repos/carvel-dev/ytt" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/vmware-tanzu/sonobuoy"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bvaughn/react-virtualized"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/opensearch-project/OpenSearch"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/opensearch-project/OpenSearch-Dashboards"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/dora-metrics/pelorus" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/migtools/mig-controller"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/migtools/mig-ui" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/migtools/mig-agnosticd"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/kubev2v/forklift-operator"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/d3/d3" element={<RepoDetails />} />
    <Route exact path="/repos/migtools/tackle-ui" element={<RepoDetails />} />
    <Route exact path="/repos/konveyor/move2kube" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/migtools/mig-operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/cloudfoundry/app-autoscaler-release"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/cloudfoundry/cli" element={<RepoDetails />} />
    <Route exact path="/repos/cloudfoundry/bosh" element={<RepoDetails />} />
    <Route exact path="/repos/cloudfoundry/uaa" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/cloudfoundry/gorouter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/SonarSource/sonarqube"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/SonarSource/docker-sonarqube"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/SonarSource/sonar-java"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/SonarSource/SonarTS" element={<RepoDetails />} />
    <Route exact path="/repos/SonarSource/SonarJS" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/argoproj/argo-workflows"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/argoproj/argo-cd" element={<RepoDetails />} />
    <Route exact path="/repos/argoproj/argo-events" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/argoproj/argo-rollouts"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/argoproj/gitops-engine"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/argoproj/argo-helm" element={<RepoDetails />} />
    <Route exact path="/repos/OpenSCAP/openscap" element={<RepoDetails />} />
    <Route exact path="/repos/keycloak/keycloak" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/GoogleCloudPlatform/microservices-demo"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/GoogleCloudPlatform/terraformer"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/GoogleCloudPlatform/opentelemetry-operations-js"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/GoogleCloudPlatform/training-data-analyst"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/angular/angular.js" element={<RepoDetails />} />
    <Route exact path="/repos/emotion-js/emotion" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/facebookexperimental/Recoil"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/antrea-io/antrea" element={<RepoDetails />} />
    <Route exact path="/repos/nfl/react-helmet" element={<RepoDetails />} />
    <Route exact path="/repos/juju/juju" element={<RepoDetails />} />
    <Route exact path="/repos/logdna/logdna-agent" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/logdna/logdna-agent-v2"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/jenkinsci/jenkins" element={<RepoDetails />} />
    <Route exact path="/repos/jenkinsci/docker" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/jenkinsci/pipeline-examples"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mobxjs/mobx" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/mobxjs/mobx-react-lite"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mobxjs/mobx-angular" element={<RepoDetails />} />
    <Route exact path="/repos/mobxjs/mobx-devtools" element={<RepoDetails />} />
    <Route exact path="/repos/pmndrs/three-stdlib" element={<RepoDetails />} />
    <Route exact path="/repos/pmndrs/jotai" element={<RepoDetails />} />
    <Route exact path="/repos/salesforce/akita" element={<RepoDetails />} />
    <Route exact path="/repos/pmndrs/valtio" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/hibernate/hibernate-orm"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/twistlock/authz" element={<RepoDetails />} />
    <Route exact path="/repos/mui/material-ui" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/hibernate/hibernate-search"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/m3db/m3" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-specification"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/trpc/trpc" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/healthchecks/healthchecks"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/jaegertracing/jaeger" element={<RepoDetails />} />
    <Route exact path="/repos/vega/vega" element={<RepoDetails />} />
    <Route exact path="/repos/thanos-io/thanos" element={<RepoDetails />} />
    <Route exact path="/repos/gohugoio/hugo" element={<RepoDetails />} />
    <Route exact path="/repos/gatsbyjs/gatsby" element={<RepoDetails />} />
    <Route exact path="/repos/octokit/rest.js" element={<RepoDetails />} />
    <Route exact path="/repos/electron/electron" element={<RepoDetails />} />
    <Route exact path="/repos/timescale/promscale" element={<RepoDetails />} />
    <Route exact path="/repos/replicatedhq/kots" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/deepfence/ThreatMapper"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/alchemyplatform/alchemy-web3"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/vercel/swr" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/hypertrace/hypertrace"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/grafana/mimir" element={<RepoDetails />} />
    <Route exact path="/repos/nats-io/nats-top" element={<RepoDetails />} />
    <Route exact path="/repos/nats-io/natscli" element={<RepoDetails />} />
    <Route exact path="/repos/greensock/GSAP" element={<RepoDetails />} />
    <Route exact path="/repos/hyperledger/fabric" element={<RepoDetails />} />
    <Route exact path="/repos/chef/chef" element={<RepoDetails />} />
    <Route exact path="/repos/kubeshop/tracetest" element={<RepoDetails />} />
    <Route exact path="/repos/kubeshop/monokle" element={<RepoDetails />} />
    <Route exact path="/repos/kubeshop/testkube" element={<RepoDetails />} />
    <Route exact path="/repos/kubeshop/kusk-gen" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubeshop/kusk-gateway"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/vmware-archive/clarity"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/stackrox/stackrox" element={<RepoDetails />} />
    <Route exact path="/repos/apache/kafka" element={<RepoDetails />} />
    <Route exact path="/repos/aquasecurity/tracee" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/aquasecurity/kube-bench"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/aquasecurity/trivy" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/screwdriver-cd/screwdriver"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/concourse/concourse" element={<RepoDetails />} />
    <Route exact path="/repos/spinnaker/spinnaker" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/tensorflow/tensorflow"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/flutter/flutter" element={<RepoDetails />} />
    <Route exact path="/repos/preactjs/preact" element={<RepoDetails />} />
    <Route exact path="/repos/golang/mock" element={<RepoDetails />} />
    <Route exact path="/repos/getsentry/sentry" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/polyfillpolyfill/polyfill-service"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/GoogleChrome/web-vitals"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/shaka-project/shaka-player"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/Paratron/hookrouter" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/TypeScript" element={<RepoDetails />} />
    <Route exact path="/repos/golang/go" element={<RepoDetails />} />
    <Route exact path="/repos/avelino/awesome-go" element={<RepoDetails />} />
    <Route exact path="/repos/mapbox/mapbox-gl-js" element={<RepoDetails />} />
    <Route exact path="/repos/mapbox/robosat" element={<RepoDetails />} />
    <Route exact path="/repos/mapbox/mapbox.js" element={<RepoDetails />} />
    <Route exact path="/repos/mapbox/tippecanoe" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/mapbox/mapbox-gl-native"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mapbox/pixelmatch" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/prometheus-community/elasticsearch_exporter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/prometheus-community/windows_exporter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/prometheus-community/helm-charts"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/prometheus-community/postgres_exporter"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/grafana/loki" element={<RepoDetails />} />
    <Route exact path="/repos/grafana/tempo" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/VictoriaMetrics/VictoriaMetrics"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/netdata/netdata" element={<RepoDetails />} />
    <Route exact path="/repos/k8spacket/k8spacket" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/redhat-developer/yaml-language-server"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/redhat-developer/vscode-java"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/redhat-developer/odo" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/vscode" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/infinispan/infinispan"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/belaban/JGroups" element={<RepoDetails />} />
    <Route exact path="/repos/denoland/fresh" element={<RepoDetails />} />
    <Route exact path="/repos/grafana/levitate" element={<RepoDetails />} />
    <Route exact path="/repos/maistra/istio" element={<RepoDetails />} />
    <Route exact path="/repos/cilium/cilium" element={<RepoDetails />} />
    <Route exact path="/repos/fluent/fluent-bit" element={<RepoDetails />} />
    <Route exact path="/repos/expressjs/express" element={<RepoDetails />} />
    <Route exact path="/repos/envoyproxy/envoy" element={<RepoDetails />} />
    <Route exact path="/repos/linkerd/linkerd2" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/MycroftAI/mycroft-core"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/microshift" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/elrumordelaluz/reactour"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/facebook/memlab" element={<RepoDetails />} />
    <Route exact path="/repos/vercel/turborepo" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/containers/bubblewrap"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/backstage/backstage" element={<RepoDetails />} />
    <Route exact path="/repos/kubernetes-sigs/kind" element={<RepoDetails />} />
    <Route exact path="/repos/pytorch/pytorch" element={<RepoDetails />} />
    <Route exact path="/repos/google/jax" element={<RepoDetails />} />
    <Route exact path="/repos/kubev2v/forklift-ui" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/spencerkimball/stargazers"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/lensapp/lens" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/aaronwangy/Data-Science-Cheatsheet"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/josephmisiti/awesome-machine-learning"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/oven-sh/bun" element={<RepoDetails />} />
    <Route exact path="/repos/tauri-apps/tauri" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/micrometer-metrics/micrometer"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/odigos-io/opentelemetry-go-instrumentation"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/nytimes/react-tracking"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/DavidWells/analytics" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/storybookjs/storybook"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/vmware-archive/octant"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-go"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-collector"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/grafana/grafonnet-lib"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/gin-gonic/gin" element={<RepoDetails />} />
    <Route exact path="/repos/jupyter/jupyter" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/ceilometer" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/glance" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/barbican" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/octavia" element={<RepoDetails />} />
    <Route exact path="/repos/openstack/heat" element={<RepoDetails />} />
    <Route exact path="/repos/cinder/Cinder" element={<RepoDetails />} />
    <Route exact path="/repos/go-co-op/gocron" element={<RepoDetails />} />
    <Route exact path="/repos/Noovolari/leapp" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/localstack/localstack"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-rust"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-python"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-java-instrumentation"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-java"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-collector-contrib"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-js"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/open-telemetry/opentelemetry-dotnet"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/brminnick/GitTrends" element={<RepoDetails />} />
    <Route exact path="/repos/abiosoft/colima" element={<RepoDetails />} />
    <Route exact path="/repos/lima-vm/lima" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/rancher-sandbox/rancher-desktop"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/awslabs/visual-asset-management-system"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openai/openai-cookbook"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openai/gym" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/Lightning-AI/pytorch-lightning"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openai/gpt-discord-bot"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openai/evals" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/scikit-learn/scikit-learn"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/microsoft/LightGBM" element={<RepoDetails />} />
    <Route exact path="/repos/tensorflow/mlir-hlo" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/Significant-Gravitas/AutoGPT"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/huggingface/transformers"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/mobxjs/mobx-state-tree"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/tensorflow/models" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/TimefoldAI/timefold-solver"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kubev2v/forklift" element={<RepoDetails />} />
    <Route exact path="/repos/axios/axios" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/cockpit-project/cockpit"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/nicolargo/glances" element={<RepoDetails />} />
    <Route exact path="/repos/openai/spinningup" element={<RepoDetails />} />
    <Route exact path="/repos/openai/baselines" element={<RepoDetails />} />
    <Route exact path="/repos/openai/retro" element={<RepoDetails />} />
    <Route exact path="/repos/openai/mujoco-py" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openai-translator/openai-translator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/sparticleinc/chatgpt-google-summary-extension"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openai/chatgpt-retrieval-plugin"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/petyosi/react-virtuoso"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/crossplane/crossplane"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/keras-team/keras" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/jfrog/project-examples"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/apache/skywalking-go" element={<RepoDetails />} />
    <Route exact path="/repos/apache/superset" element={<RepoDetails />} />
    <Route exact path="/repos/apache/flink" element={<RepoDetails />} />
    <Route exact path="/repos/apache/iotdb" element={<RepoDetails />} />
    <Route exact path="/repos/apache/spark" element={<RepoDetails />} />
    <Route exact path="/repos/apache/dubbo" element={<RepoDetails />} />
    <Route exact path="/repos/apache/echarts" element={<RepoDetails />} />
    <Route exact path="/repos/JuliaLang/julia" element={<RepoDetails />} />
    <Route exact path="/repos/rails/rails" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubernetes-sigs/gateway-api"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/origin-branding"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/must-gather"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/cluster-config-operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/cluster-openshift-controller-manager-operator"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/heapster" element={<RepoDetails />} />
    <Route exact path="/repos/openshift/installer" element={<RepoDetails />} />
    <Route exact path="/repos/openshift/library-go" element={<RepoDetails />} />
    <Route exact path="/repos/openshift/console" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openshift/cluster-monitoring-operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/service-idler"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/autoheal" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openshift/origin-web-console-smoke-test"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/monitor-sample-app"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/monitor-project-lifecycle"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/online-gluster-subvol"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/online-console-extensions"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/cluster-operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/image-registry"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/mpbarrett/openshift-quickstarts"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/descheduler"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/node-problem-detector"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/online-analytics"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/eventrouter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/node_exporter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/image-inspector-client-ruby"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/online-archivist"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/online-hibernation"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/clam-scanner"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/openshift-logos-icon"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/sclorg/httpd-ex" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openshift/open-service-broker-sdk"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/kubernetes-apiserver"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/kubernetes-client-go"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/origin-web-common"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/origin-web-catalog"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/php-ex" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openshift/jenkins-client-plugin"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/jee-ex" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openshift/registry-image-widgets"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/test-maven-app"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/radanalyticsio/oshinko-s2i"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/origin-web-console"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/jenkins-sync-plugin"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/object-describer"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/openshift-origin-design"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/openshift-state-metrics"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift-eng/elliott"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift-eng/aos-cd-jobs"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/jaredpalmer/formik" element={<RepoDetails />} />
    <Route exact path="/repos/vercel/ai-chatbot" element={<RepoDetails />} />
    <Route exact path="/repos/epinio/epinio" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/kubewarden/policy-server"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/travis-ci/travis-ci" element={<RepoDetails />} />
    <Route exact path="/repos/twbs/bootstrap" element={<RepoDetails />} />
    <Route exact path="/repos/dagger/dagger" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/JARVIS" element={<RepoDetails />} />
    <Route exact path="/repos/nomic-ai/gpt4all" element={<RepoDetails />} />
    <Route exact path="/repos/envoyproxy/gateway" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/envoyproxy/data-plane-api"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/envoyproxy/ratelimit" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/envoyproxy/go-control-plane"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/orbitbot/chrome-extensions-examples"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/envoyproxy/envoy-mobile"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/slok/sloth" element={<RepoDetails />} />
    <Route exact path="/repos/pinojs/pino" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/elasticsearch-dump/elasticsearch-dump"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/k3s-io/k3s" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/ionic-team/ionic-framework"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/otaviof/podman-sh" element={<RepoDetails />} />
    <Route exact path="/repos/ladjs/superagent" element={<RepoDetails />} />
    <Route exact path="/repos/ionic-team/stencil" element={<RepoDetails />} />
    <Route exact path="/repos/ionic-team/capacitor" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/containers/podman-desktop"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/tailwindlabs/headlessui"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/solidjs/solid" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/tailwindlabs/tailwindcss"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/ggerganov/whisper.cpp"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/getumbrel/llama-gpt" element={<RepoDetails />} />
    <Route exact path="/repos/ggerganov/llama.cpp" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/tatsu-lab/stanford_alpaca"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/bigskysoftware/htmx" element={<RepoDetails />} />
    <Route exact path="/repos/bebop/poly" element={<RepoDetails />} />
    <Route exact path="/repos/termux/termux-app" element={<RepoDetails />} />
    <Route exact path="/repos/simonw/datasette" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/responsively-org/responsively-app"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/nuxt/nuxt" element={<RepoDetails />} />
    <Route exact path="/repos/mockoon/mockoon" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/JessicaTegner/pypandoc"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/GyulyVGC/sniffnet" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/formbricks/formbricks"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/fastai/nbdev" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/FashionFreedom/Seamly2D"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/EddieHubCommunity/BioDrop"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/code-hike/codehike" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/Atri-Labs/atrilabs-engine"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/analogjs/analog" element={<RepoDetails />} />
    <Route exact path="/repos/DioxusLabs/dioxus" element={<RepoDetails />} />
    <Route exact path="/repos/spyder-ide/spyder" element={<RepoDetails />} />
    <Route exact path="/repos/projectcalico/calico" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/star-history/star-history"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/automerge/automerge" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/TimefoldAI/timefold-quickstarts"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/TimefoldAI/timefold-presentations"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/ClickHouse/ClickHouse"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VictoriaMetrics/fastcache"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VictoriaMetrics/metrics"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VictoriaMetrics/ansible-playbooks"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VictoriaMetrics/metricsql"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VictoriaMetrics/operator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VictoriaMetrics/helm-charts"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/vsphere-problem-detector"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/openshift/console-operator"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openshift/coredns" element={<RepoDetails />} />
    <Route exact path="/repos/openshift/router" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/NativeScript/NativeScript"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/urql-graphql/urql" element={<RepoDetails />} />
    <Route exact path="/repos/pmndrs/zustand" element={<RepoDetails />} />
    <Route exact path="/repos/mrdoob/three.js" element={<RepoDetails />} />
    <Route exact path="/repos/pmndrs/use-gesture" element={<RepoDetails />} />
    <Route exact path="/repos/pmndrs/react-spring" element={<RepoDetails />} />
    <Route exact path="/repos/airbnb/javascript" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/huggingface/huggingface.js"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/huggingface/accelerate"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/huggingface/diffusers"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/huggingface/datasets" element={<RepoDetails />} />
    <Route exact path="/repos/databrickslabs/dolly" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/google-research/google-research"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/google-research/bert" element={<RepoDetails />} />
    <Route exact path="/repos/containers/image" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/langchain-ai/langchain"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/makeplane/plane" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/cohere-ai/sandbox-conversant-lib"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/cohere-ai/cohere-python"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/cohere-ai/sandbox-topically"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/cohere-ai/notebooks" element={<RepoDetails />} />
    <Route exact path="/repos/ziglang/zig" element={<RepoDetails />} />
    <Route exact path="/repos/harness/gitness" element={<RepoDetails />} />
    <Route exact path="/repos/ankane/pghero" element={<RepoDetails />} />
    <Route exact path="/repos/cryostatio/cryostat" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/DeepSpeed" element={<RepoDetails />} />
    <Route exact path="/repos/korrel8r/korrel8r" element={<RepoDetails />} />
    <Route exact path="/repos/prebid/Prebid.js" element={<RepoDetails />} />
    <Route exact path="/repos/apache/airflow" element={<RepoDetails />} />
    <Route exact path="/repos/orbstack/orbstack" element={<RepoDetails />} />
    <Route exact path="/repos/apple/ml-ferret" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/observIQ/bindplane-agent"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/facebookresearch/segment-anything"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/openai/whisper" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/labmlai/annotated_deep_learning_paper_implementations"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/f/awesome-chatgpt-prompts"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/TanStack/query" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/forcedotcom/Einstein-GPT-for-Developers"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/KxSystems/kdb" element={<RepoDetails />} />
    <Route exact path="/repos/epam/JDI" element={<RepoDetails />} />
    <Route exact path="/repos/solo-io/bumblebee" element={<RepoDetails />} />
    <Route exact path="/repos/solo-io/squash" element={<RepoDetails />} />
    <Route exact path="/repos/solo-io/unik" element={<RepoDetails />} />
    <Route exact path="/repos/solo-io/gloo" element={<RepoDetails />} />
    <Route exact path="/repos/apple/pkl" element={<RepoDetails />} />
    <Route exact path="/repos/nodejs/node" element={<RepoDetails />} />
    <Route exact path="/repos/ollama/ollama" element={<RepoDetails />} />
    <Route exact path="/repos/QuivrHQ/quivr" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/run-llama/llama_index"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/run-ai/genv" element={<RepoDetails />} />
    <Route exact path="/repos/google/gemma_pytorch" element={<RepoDetails />} />
    <Route exact path="/repos/Azure/PyRIT" element={<RepoDetails />} />
    <Route exact path="/repos/open-mpi/ompi" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/zed-industries/extensions"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/zed-industries/zed" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/bigcode-project/jupytercoder"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/transformers"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/octopack"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/bigcode-evaluation-harness"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/starcoder"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/bigcode-dataset"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/Megatron-LM"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/bigcode-project/starcoder2"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/VincentGranville/Large-Language-Models"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/milvus-io/milvus" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/mckaywrigley/ai-code-translator"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/mckaywrigley/chatbot-ui"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/weaviate/weaviate" element={<RepoDetails />} />
    <Route exact path="/repos/debezium/debezium" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/fireship-io/fireship.io"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/gptscript-ai/gptscript"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/xai-org/grok-1" element={<RepoDetails />} />
    <Route exact path="/repos/Azure/kaito" element={<RepoDetails />} />
    <Route exact path="/repos/meta-llama/llama" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/r3bl-org/r3bl-open-core"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/Future-Scholars/paperlib"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/testcontainers/testcontainers-dotnet"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/testcontainers/testcontainers-node"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/testcontainers/testcontainers-python"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/testcontainers/testcontainers-go"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/testcontainers/testcontainers-java"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/highlightjs/highlight.js"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/OpenInterpreter/open-interpreter"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/Sinaptik-AI/pandas-ai"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/codership/galera" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/Stability-AI/StableStudio"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/Stability-AI/StableLM"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/Stability-AI/generative-models"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/Stability-AI/stablediffusion"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/apple/ml-stable-diffusion"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/apple/coremltools" element={<RepoDetails />} />
    <Route exact path="/repos/WecoAI/aideml" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/unilm" element={<RepoDetails />} />
    <Route exact path="/repos/opentofu/opentofu" element={<RepoDetails />} />
    <Route exact path="/repos/prodzilla/prodzilla" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/garnet" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/Mintplex-Labs/anything-llm"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/langgenius/dify" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/databricks/megablocks"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mosaicml/llm-foundry" element={<RepoDetails />} />
    <Route exact path="/repos/databricks/dbrx" element={<RepoDetails />} />
    <Route exact path="/repos/meta-llama/llama3" element={<RepoDetails />} />
    <Route exact path="/repos/kubesaw/ksctl" element={<RepoDetails />} />
    <Route exact path="/repos/qdrant/qdrant" element={<RepoDetails />} />
    <Route exact path="/repos/resend/react-email" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/windmill-labs/windmill"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/massalabs/massa" element={<RepoDetails />} />
    <Route exact path="/repos/hpcaitech/ColossalAI" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/sismo-core/sismo-badges"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/apitable/apitable" element={<RepoDetails />} />
    <Route exact path="/repos/reflex-dev/reflex" element={<RepoDetails />} />
    <Route exact path="/repos/QwikDev/qwik" element={<RepoDetails />} />
    <Route exact path="/repos/apache/datafusion" element={<RepoDetails />} />
    <Route exact path="/repos/IceWhaleTech/CasaOS" element={<RepoDetails />} />
    <Route exact path="/repos/invoke-ai/InvokeAI" element={<RepoDetails />} />
    <Route exact path="/repos/upscayl/upscayl" element={<RepoDetails />} />
    <Route exact path="/repos/tinygrad/tinygrad" element={<RepoDetails />} />
    <Route exact path="/repos/tldraw/tldraw" element={<RepoDetails />} />
    <Route exact path="/repos/jaredpalmer/razzle" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/Snowflake-Labs/snowflake-arctic"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/neuralmagic/deepsparse"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/getlago/lago" element={<RepoDetails />} />
    <Route exact path="/repos/delta-io/delta" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/open-webui/open-webui"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/microsoft/retina" element={<RepoDetails />} />
    <Route exact path="/repos/radix-ui/themes" element={<RepoDetails />} />
    <Route exact path="/repos/radix-ui/icons" element={<RepoDetails />} />
    <Route exact path="/repos/radix-ui/colors" element={<RepoDetails />} />
    <Route exact path="/repos/radix-ui/primitives" element={<RepoDetails />} />
    <Route exact path="/repos/shadcn-ui/ui" element={<RepoDetails />} />
    <Route exact path="/repos/artetxem/vecmap" element={<RepoDetails />} />
    <Route exact path="/repos/sourcegraph/conc" element={<RepoDetails />} />
    <Route exact path="/repos/yui/yui3" element={<RepoDetails />} />
    <Route exact path="/repos/Modernizr/Modernizr" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/nutanix/kubectl-karbon"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/nutanix/libvfio-user" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/nutanix/nutanix.ansible"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/nutanix-cloud-native/cluster-api-provider-nutanix"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/instructlab/instructlab"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/scipy/scipy" element={<RepoDetails />} />
    <Route exact path="/repos/mwaskom/seaborn" element={<RepoDetails />} />
    <Route exact path="/repos/pandas-dev/pandas" element={<RepoDetails />} />
    <Route exact path="/repos/Theano/Theano" element={<RepoDetails />} />
    <Route exact path="/repos/numpy/numpy" element={<RepoDetails />} />
    <Route exact path="/repos/truefoundry/cognita" element={<RepoDetails />} />
    <Route exact path="/repos/truera/trulens" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/mistralai/client-python"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/mistralai/megablocks-public"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mistralai/cookbook" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/mistralai/mistral-finetune"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mistralai/client-js" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/mistralai/mistral-inference"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/triton-lang/triton" element={<RepoDetails />} />
    <Route exact path="/repos/vynjo/hashiconf-demo" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/openclarity/apiclarity"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/immich-app/immich" element={<RepoDetails />} />
    <Route exact path="/repos/iterative/dvc" element={<RepoDetails />} />
    <Route exact path="/repos/minio/minio" element={<RepoDetails />} />
    <Route exact path="/repos/mlflow/mlflow" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/huggingface/optimum-quanto"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/kserve/kserve" element={<RepoDetails />} />
    <Route exact path="/repos/vllm-project/vllm" element={<RepoDetails />} />
    <Route exact path="/repos/ray-project/kuberay" element={<RepoDetails />} />
    <Route exact path="/repos/pachyderm/pachyderm" element={<RepoDetails />} />
    <Route exact path="/repos/coreos/butane" element={<RepoDetails />} />
    <Route exact path="/repos/traefik/traefik" element={<RepoDetails />} />
    <Route exact path="/repos/dmlc/xgboost" element={<RepoDetails />} />
    <Route exact path="/repos/brotandgames/ciao" element={<RepoDetails />} />
    <Route exact path="/repos/statping/statping" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/NVIDIA/DeepLearningExamples"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/opencv/opencv" element={<RepoDetails />} />
    <Route exact path="/repos/kubeflow/pipelines" element={<RepoDetails />} />
    <Route exact path="/repos/kubeflow/kubeflow" element={<RepoDetails />} />
    <Route exact path="/repos/kubeflow/katib" element={<RepoDetails />} />
    <Route exact path="/repos/feast-dev/feast" element={<RepoDetails />} />
    <Route exact path="/repos/trinodb/trino" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/langchain-ai/langchainjs"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/langchain-ai/langsmith-sdk"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/langchain-ai/langserve"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/langchain-ai/langgraph"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/langchain-ai/chat-langchain"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/langchain-ai/opengpts"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/anthropics/anthropic-cookbook"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/SUSE/phoebe" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/cockroachdb/cockroach"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/pingcap/tidb" element={<RepoDetails />} />
    <Route exact path="/repos/apache/zookeeper" element={<RepoDetails />} />
    <Route exact path="/repos/typicode/lowdb" element={<RepoDetails />} />
    <Route exact path="/repos/taosdata/TDengine" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/dragonflydb/dragonfly"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/mongodb/mongo" element={<RepoDetails />} />
    <Route exact path="/repos/surrealdb/surrealdb" element={<RepoDetails />} />
    <Route exact path="/repos/metabase/metabase" element={<RepoDetails />} />
    <Route exact path="/repos/prisma/prisma" element={<RepoDetails />} />
    <Route exact path="/repos/dbeaver/dbeaver" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/meilisearch/meilisearch"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/redis/redis" element={<RepoDetails />} />
    <Route exact path="/repos/supabase/supabase" element={<RepoDetails />} />
    <Route exact path="/repos/cockroachdb/pebble" element={<RepoDetails />} />
    <Route exact path="/repos/influxdata/influxdb" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/VictoriaMetrics/victoriametrics-datasource"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/swiftlang/swift" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/apollographql/apollo-client"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/apollographql/rover" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/apollographql/react-apollo"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/apollographql/apollo-server"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/zitadel/zitadel" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/meta-llama/llama-recipes"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/meta-llama/PurpleLlama"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/meta-llama/llama-models"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/swiftlang/llvm-project"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/nebuly-ai/optimate" element={<RepoDetails />} />
    <Route exact path="/repos/FUSAKLA/promruval" element={<RepoDetails />} />
    <Route exact path="/repos/axiomhq/axiom-js" element={<RepoDetails />} />
    <Route exact path="/repos/axiomhq/quantiles" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/axiomhq/rust-cuckoofilter"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/axiomhq/hyperminhash" element={<RepoDetails />} />
    <Route exact path="/repos/axiomhq/next-axiom" element={<RepoDetails />} />
    <Route exact path="/repos/axiomhq/hyperloglog" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/Trace" element={<RepoDetails />} />
    <Route exact path="/repos/microsoft/promptflow" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/microsoft/semantic-kernel"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/microsoft/tensorwatch"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/microsoft/LMOps" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/janus-idp/backstage-showcase"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/janus-idp/backstage-plugins"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/kubernetes-sigs/kubespray"
      element={<RepoDetails />}
    />
    <Route
      exact
      path="/repos/kubernetes-sigs/karpenter"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/nteract/nteract" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/matplotlib/matplotlib"
      element={<RepoDetails />}
    />
    <Route exact path="/repos/conda/conda" element={<RepoDetails />} />
    <Route exact path="/repos/numba/numba" element={<RepoDetails />} />
    <Route exact path="/repos/wesm/pydata-book" element={<RepoDetails />} />
    <Route
      exact
      path="/repos/meta-llama/llama-stack"
      element={<RepoDetails />}
    />
  </Routes>
);

export default RouterList;
