import React, { forwardRef, useEffect, useMemo } from 'react';
// import { useTheme, useMediaQuery } from '@mui/system';
import { useTable, useSortBy, usePagination } from 'react-table';
import Button from '@mui/material/Button';
// import { styled } from '@mui/system';
import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import RepoLibraryLookupMenu from './RepoLibraryTableButton.jsx';
// const Styles = styled('div')(({ theme }) => ({
//   table: {
//     borderSpacing: 0,
//     width: '100%',
//   },
//   th: {
//     margin: 0,
//     // fontSize: '0.9em',
//     padding: '0.5rem',
//     '&:(:last-child)': {
//       borderBottom: 0,
//       borderRight: 0,
//     },
//   },
//   td: {
//     margin: 0,
//     backgroundColor:
//       theme.palette.mode === 'dark'
//         ? 'rgba(255, 255, 255, .05)'
//         : 'rgba(0, 0, 0, .03)',
//     padding: '0.3rem',
//     borderBottom: '1px solid black',
//     '&:(:last-child)': {
//       borderBottom: 0,
//       borderRight: 0,
//     },
//   },
//   tr: {
//     backgroundColor:
//       theme.palette.mode === 'dark'
//         ? 'rgba(255, 255, 255, .05)'
//         : 'rgba(0, 0, 0, .03)',
//   },
//   pagecontrols: {
//     padding: '0.5rem',
//   },
//   spantext: {
//     fontSize: '0.5em',
//   },
// }));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

function LookupTable({ columns, data, setRepoToLookUp }) {
  // const [menuselected, setMenuSelected] = useState(false);

  // function MenuSelected(event) {
  //   setMenuSelected(event.target.checked);
  // }
  function onRowClick(row, column, e) {
    let n = row.RepoUser + '/' + row.RepoName;
    setRepoToLookUp(n);
    // console.log('column = ', column);
    if (column.Header != 'Action') {
      window.scrollTo(0, 0);
    }
    if (e.metaKey) {
      window.open('https://www.gitgenius.co/repos/' + n); //, _blank');
    } else if (e.shiftKey) {
      window.open('https://github.com/' + n, '_blank');
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: defaultColumn,
      // initialState: { pageSize: 30, hiddenColumns: ['id', 'DateAdded'] },
      initialState: { pageSize: 30 },
    },
    useSortBy,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  return (
    <Container
      sx={{
        flexGrow: 1,
        color: 'primary.main',
        fontSize: { xs: '1em', md: '1em' },
      }}
    >
      {/* <Box sx={{ flexGrow: 1, color: 'primary.main', fontSize: '0.9em' }}> */}

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={Math.random()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  key={Math.random()}
                  style={{
                    width: column.id === 'Action' ? 10 : 'auto',
                    textDecoration: 'underline',
                  }}
                >
                  {column.id != 'Action' && (
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {generateSortingIndicator(column)}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={i}
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  cursor: 'pointer',
                }}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      onClick={(e) => {
                        onRowClick(row.original, cell.column, e);
                      }}
                      {...cell.getCellProps((props, { cell }) =>
                        cell.column.id === 'RepoUser' ||
                        cell.column.id === 'RepoName'
                          ? [
                              props,
                              {
                                style: {
                                  maxWidth: 100,
                                  wordWrap: 'break-word',
                                },
                              },
                            ]
                          : props
                      )}
                      key={row.id + '-' + i}
                    >
                      {cell.column.id === 'Action' && (
                        <RepoLibraryLookupMenu row={row} />
                      )}
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagecontrols">
        <div>
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardDoubleArrowLeftRoundedIcon />
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardArrowLeftRoundedIcon />
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardArrowRightRoundedIcon />
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            style={{ minWidth: 50 }}
          >
            <KeyboardDoubleArrowRightRoundedIcon />
          </Button>
          <Box component="span" sx={{ color: 'primary.main' }}>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1, color: 'primary.main', fontSize: '0.9em' }}>
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item xs={3}>
              <TextField
                id="pagesize"
                select
                label="# of Repos"
                size="small"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                variant="filled"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              {/* {response} */}
            </Grid>
          </Grid>
        </Box>
      </div>
    </Container>
  );
}
//  A solution to hiding column on small screen - https://codesandbox.io/p/sandbox/nn1y6q1rv4?file=%2Fsrc%2Findex.js
function RepoLookupibraryTable(props) {
  var theme = useTheme();
  var columns;
  if (useMediaQuery(theme.breakpoints.down('sm')) == true) {
    columns = useMemo(
      () => [
        {
          Header:
            'Repositories in Library (click to lookup, click shift or command/meta to open new window/tab',
          columns: [
            {
              Header: 'User',
              accessor: 'RepoUser',
            },
            {
              Header: 'Repo Name',
              accessor: 'RepoName',
            },
          ],
        },
      ],
      []
    );
  } else {
    columns = useMemo(
      () => [
        {
          Header:
            'Repositories in Library (click to lookup, click shift or command/meta to open new window/tab',
          columns: [
            {
              Header: 'Action',
              // accessor: 'RepoUser',
            },
            {
              Header: 'User',
              accessor: 'RepoUser',
            },
            {
              Header: 'Repo Name',
              accessor: 'RepoName',
            },
            {
              Header: 'Added',
              accessor: 'DateAdded',
            },
          ],
        },
      ],
      []
    );
  }
  // console.log('Styles = ', Styles);
  return (
    // <Styles theme={theme}>
    <LookupTable
      columns={columns}
      data={props.libraryList}
      setRepoToLookUp={props.setRepoToLookUp}
    />
    // </Styles>
  );
}

export default RepoLookupibraryTable;
