import React from 'react';
// import styled from 'styled-components';
import { styled as muistyled } from '@mui/system';

import { HighlightOff } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

// A useful example - https://codesandbox.io/s/dnd-kit-vertical-variable-height-removable-draggable-rsc67?file=/src/App.js:1393-1416
const MuiHandleWrapper = muistyled('div')({
  height: '1rem',
  width: '2em',
  verticalAlign: 'top',
  float: 'right',
  marginRight: '0.5rem',
  svg: {
    width: '100%',
    height: '100%',
  },
});

// const HandleWrapper = styled.div`
//   height: 1rem;
//   width: 2em;
//   vertical-align: top;
//   // display: inline-block;
//   float: right;
//   margin-right: 0.5rem;
//   svg {
//     width: 100%;
//     height: 100%;
//   }
// `;

export const RemoveHandle = ({ onRemove, cell }) => {
  return (
    <Tooltip title="Remove">
      <MuiHandleWrapper>
        <div
          style={{
            height: '1rem',
            float: 'right',
            cursor: 'pointer',
          }}
          onClick={() => {
            onRemove(cell.row.id);
          }}
        >
          <HighlightOff />
        </div>
      </MuiHandleWrapper>
    </Tooltip>
  );
};
