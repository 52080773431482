import React from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import withStyles from '@mui/styles/withStyles';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import registerStyle from './registerStyle.jsx';
import { Button, Container, Grid, Paper } from '@mui/material';

import TextField from '@mui/material/TextField';
import agent from '../../agentAxios.jsx';
import { Helmet } from 'react-helmet-async';

import {
  REGISTER,
  REGISTER_PAGE_LOADED,
  REGISTER_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  ...state.auth,
  isLoggedIn: state.common.isLoggedIn,
});

// https://formik.org/docs/tutorial
const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: REGISTER_PAGE_LOADED }),
  onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
});

const MyTextInput = ({ label, classes, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  var errorstatus = '';
  if (props.errors) {
    if (field.name in props.errors) {
      if (field.name == 'Code') {
        errorstatus = 'Code is incorrect';
      } else if (field.name == 'Email') {
        errorstatus = 'Email address already in use';
      } else if (field.name == 'Username') {
        errorstatus = 'Username already in use by another user';
      }
    }
  }
  return (
    <Grid item xs={12} md={8}>
      <Paper className={classes.paper}>
        {/* <label htmlFor={props.id || props.name}>{label}</label> */}
        <TextField
          fullWidth
          type="text"
          className="text-input"
          label={label}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className={classes.errortext}>{meta.error}</div>
        ) : null}
        {errorstatus != '' ? (
          <div className={classes.errortext}>{errorstatus}</div>
        ) : null}
      </Paper>
    </Grid>
  );
};

const MyCheckbox = ({ children, classes, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <Grid item xs={12} md={8}>
      <Paper className={classes.paper}>
        <label className="checkbox-input">
          <TextField type="checkbox" {...field} {...props} />
          {children}
        </label>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </Paper>
    </Grid>
  );
};

const Register = (props) => {
  const { classes } = props;
  // const isLoggedIn = useSelector((state) => state.common.isLoggedIn);
  document.title = 'GitGenius - Registration';
  let navigate = useNavigate();

  const dispatch = useDispatch();
  // formally componentDidMount and componentWillUnmount dispatches now user useEffect
  React.useEffect(() => {
    dispatch({ type: REGISTER_PAGE_LOADED });
    return () => {
      dispatch({ type: REGISTER_PAGE_UNLOADED });
    };
  }, []);

  // if (isLoggedIn) {
  //   ReactGA4.event({
  //     category: 'User',
  //     action: 'New Registration',
  //   });
  //   console.log('isLoggedIn');
  //   // navigate('/stargazeroverview');
  // }
  // console.log('props.errors = ', props.errors, name);
  return (
    <Container maxWidth="sm" sx={{ paddingTop: '80px', minHeight: '100vh' }}>
      <Helmet>
        <title>GitGenius - Register for your GitGenius account</title>
        <meta
          name="description"
          content="Register for your GitGenius account"
        />
        <link rel="canonical" href="https://www.gitgenius.co/register" />
      </Helmet>
      <Formik
        initialValues={{
          Code: 'free',
          Firstname: '',
          Lastname: '',
          Username: '',
          Email: '',
          Password: '',
          confirmPassword: '',
          acceptedTerms: false, // added for our checkbox
          // jobType: '', // added for our select
        }}
        validationSchema={Yup.object({
          Code: Yup.string().required('Required'),
          Firstname: Yup.string().max(20, 'Must be 20 characters or less'),
          // .required('Required'),
          Lastname: Yup.string().max(20, 'Must be 20 characters or less'),
          // .required('Required'),
          Username: Yup.string()
            .max(20, 'Must be 20 characters or less')
            .required('Required'),
          Email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          Password: Yup.string().required('Please Enter your Password'),
          // .matches(
          //   '^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$',
          //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
          // ),
          confirmPassword: Yup.string()
            .required()
            .oneOf([Yup.ref('Password'), null], 'Passwords must match'),
          acceptedTerms: Yup.boolean()
            .required('Required')
            .oneOf([true], 'You must accept the terms and conditions.'),
        })}
        onSubmit={(values) => {
          // console.log('values = ', values);
          const payload = agent.Auth.register(
            values.Code,
            values.Firstname,
            values.Lastname,
            values.Username,
            values.Email,
            values.Password
          );
          // console.log('before dispatch, payload = ', payload);
          payload.then((result) => {
            // console.log('payload error = ', result.errors);
            // console.log('payload user = ', result.user);
            dispatch({ type: REGISTER, payload });
            if (!result.error && result.user) {
              window.gtag('event', 'Page Read', {
                event_category: 'Register',
                event_label: 'User ' + values.Email,
              });
              navigate('/stargazeroverview');
            }
          });
        }}
      >
        <Form sx={{ minWidth: '100%' }}>
          <Grid item s={12} md={8}>
            <Paper className={classes.paper}>
              <h1 className="text-xs-center">Sign Up</h1>
              <p className="text-xs-center">
                <Link to="/login">Have an account?</Link>
              </p>
            </Paper>
          </Grid>
          <MyTextInput
            classes={classes}
            label="Invitation Code"
            name="Code"
            type="text"
            errors={props.errors}
          />
          <MyTextInput
            classes={classes}
            label="First Name"
            name="Firstname"
            type="text"
            errors={props.errors}
          />
          <MyTextInput
            classes={classes}
            label="Last Name"
            name="Lastname"
            type="text"
            errors={props.errors}
          />
          <MyTextInput
            classes={classes}
            label="User Name"
            name="Username"
            type="text"
            errors={props.errors}
          />
          <MyTextInput
            classes={classes}
            label="Email Address"
            name="Email"
            type="email"
            placeholder="user@domain.com"
            errors={props.errors}
          />
          <MyTextInput
            classes={classes}
            label="Password"
            name="Password"
            type="password"
            errors={props.errors}
          />
          <MyTextInput
            classes={classes}
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            errors={props.errors}
          />
          <MyCheckbox classes={classes} name="acceptedTerms">
            I accept the <a href="/terms_of_service">Terms of Service</a>
          </MyCheckbox>
          <Grid item xs={12} md={8}>
            <Paper className={classes.paper}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
              {/* <button type="submit">Submit</button> */}
            </Paper>
          </Grid>
        </Form>
      </Formik>
      {/* </Box> */}
    </Container>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerStyle)(Register));
