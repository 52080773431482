import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import RepoIcon from '@mui/icons-material/DeviceHub';
// import RepoIcon from '@mui/icons-material/Timeline';
import RepoIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import { Container } from '@mui/system';
// import { useSelector } from 'react-redux';

// function isJsonString(str) {
//   try {
//     JSON.parse(str);
//   } catch (e) {
//     return false;
//   }
//   return true;
// }

const RepoMenuItems = (props) => {
  if (props.Repos) {
    // var rd = useSelector((state) => state.common.currentUser.repolist);
    // var rd = props.Repos.Repolist;
    // console.log('in RepoMenuItems, repos =   = ', props.Repos.Repolist);
    let RepoData = JSON.parse(props.Repos.Repolist);
    // if (rd && isJsonString(rd)) {
    //   RepoData = JSON.parse(rd);
    //   props.Repos.Repolist = rd;
    // }
    // console.log('RepoData length = ', RepoData.length);
    var count = RepoData.length;
    var repolist = [];
    // console.log('props = ', RepoData[0].user);
    // var repodata = JSON.parse(props.Repos.Repolist);
    // console.log('props = ', repodata[0].user);
    // console.log('props = ', props.Repos);
    var repos = Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `repo-${k}`,
      user: `${RepoData[k].user}`,
    }));
    repos.forEach((r, index) => {
      r.reponames = RepoData[index].reponames.map((x) => x);
      // console.log('In Map, user = ', r.user);
      r.reponames.forEach((name) => {
        var temprepo = r.user + '/' + name;
        // repolist.push({ Name: temprepo });
        repolist.push({
          fullname: temprepo,
          key: temprepo,
          ruser: r.user,
          rname: name,
          title: temprepo,
          href: temprepo,
        });
        // console.log('data = ', name);
      });
    });
    // console.log('list = ', repolist);
    // https://codesandbox.io/s/vj6noq66w0?file=/demo.js
    // console.log('location is ', window.location.hash);
    // console.log('in RepoMenuItems ');

    return (
      <Container disableGutters>
        <List dense>
          {repolist.map(
            ({
              key: childKey,
              rname: childTitle,
              ruser: userKey,
              fullname: fullName,
            }) => (
              <ListItem
                // button
                key={childKey}
                title={childTitle}
                component={Link}
                // https://github.com/ReactTraining/react-router/blob/dc10832d23f9ab673891805c9c866b90d1ef1ed6/packages/react-router-dom/docs/api/Link.md#to-object
                to={{ pathname: `/repos/` + `${fullName}` }}
                onClick={props.handleDrawerClose}
                // selected={'#' + fullName == window.location.hash}
                dense
              >
                <ListItemIcon>
                  <Tooltip title={fullName}>
                    <RepoIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  inset={false}
                  primary={childTitle}
                  secondary={userKey}
                  sx={{
                    maxWidth: '200px',
                    whiteSpace: 'wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                />
              </ListItem>
            )
          )}
        </List>
      </Container>
    );
  }
};

export default RepoMenuItems;
