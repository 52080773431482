import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Paper, Typography } from '@mui/material';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function FormRow(props) {
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Item>
          <Typography>{props.item1name}</Typography>
          <Typography sx={{ fontSize: '0.8em' }}>
            {props.item1subhead}
          </Typography>
          <Typography
            sx={{ display: 'inline', fontSize: '1.5em' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {props.item1}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Typography>{props.item2name}</Typography>
          <Typography sx={{ fontSize: '0.8em' }}>
            {props.item2subhead}
          </Typography>
          <Typography
            sx={{ display: 'inline', fontSize: '1.5em' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {props.item2}
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Typography>{props.item3name}</Typography>
          <Typography sx={{ fontSize: '0.8em' }}>
            {props.item3subhead}
          </Typography>
          <Typography
            sx={{ display: 'inline', fontSize: '1.5em' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {props.item3}
          </Typography>
        </Item>{' '}
      </Grid>
    </React.Fragment>
  );
}

export default function AdminMetricGrid(props) {
  return (
    <Box sx={{ flexGrow: 1, fontSize: '0.9em' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography>Updates</Typography>
        <Typography style={{ color: 'gray' }}>(updates on Interval)</Typography>
      </div>
      <Grid container spacing={1}>
        <Grid container item spacing={1}>
          <FormRow
            item1={props.userlastmin}
            item1name="Per Min"
            // item1subhead="(updates on Interval)"
            item2={props.userlasthour}
            item2name="Per Hour"
            // item2subhead="(updates on Interval)"
            item3={props.userlastday}
            item3name="Per Day"
            // item3subhead="(updates on Interval)"
          />
        </Grid>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
          <Typography>Totals</Typography>
          <Typography style={{ color: 'gray' }}>
            (updates on Page Load)
          </Typography>
        </div>
        <Grid container item spacing={1}>
          <FormRow
            item1={props.totalusers}
            item1name="Users"
            // item1subhead="(updates on Page Load)"
            item2={props.totalorgs}
            item2name="Organizations"
            // item2subhead="(updates on Page Load)"
            item3={props.total404}
            item3name="Cancelled"
            // item3subhead="(updates on Page Load)"
          />
        </Grid>
        <Grid container item spacing={1}>
          <FormRow
            item1={props.totalrepos}
            item1name="Repositories"
            // item1subhead="(updates on Page Load)"
            item2={props.numofdbusers}
            item2name="Users"
            // item2subhead="(updates on Page Load)"
            item3={props.numunitialized}
            item3name="Unititialized"
            // item3subhead="(updates on Interval)"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
