import React, { useState, useEffect } from 'react';
import GridContainer from '../../modules/Grid/GridContainer.jsx';
import GridItem from '../../modules/Grid/GridItem.jsx';
import CustomInput from '../../modules/CustomInput/CustomInput.jsx';
import Button from '../../modules/CustomButtons/Button.jsx';
import withRouter from '../../routes/withRouter.jsx';

const ResetPasswordForm = ({ currentUser, onSubmitForm, router }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    bio: '',
    email: '',
    password: '',
    gittoken: '',
    darkmode: '',
    repolist: '',
  });

  useEffect(() => {
    if (currentUser) {
      setFormData({
        ...formData,
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        username: currentUser.username,
        email: currentUser.email,
        gittoken: currentUser.gittoken,
        bio: currentUser.bio,
        darkmode: currentUser.darkmode,
        repolist: currentUser.Repolist,
        member_status: currentUser.member_status,
        preferences: currentUser.preferences,
      });
    }
  }, [currentUser]);

  const updateState = (field) => (ev) => {
    setFormData({ ...formData, [field]: ev.target.value });
  };

  const submitForm = (ev) => {
    ev.preventDefault();
    onSubmitForm(formData);
    try {
      router.navigate('/stargazeroverview');
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <div className="root">
      <form onSubmit={submitForm}>
        <GridContainer justifyContent="center">
          <GridItem className="form-group">
            <CustomInput
              className="form-control form-control-lg"
              type="password"
              autoComplete="current-password"
              labelText="New Password"
              onChange={updateState('password')}
            />
          </GridItem>
          <Button
            className="settingButton"
            color="primary"
            type="submit"
            disabled={formData.inProgress}
          >
            Submit
          </Button>
        </GridContainer>
      </form>
      <div style={{ minHeight: '50px' }}></div>
    </div>
  );
};

export default withRouter(ResetPasswordForm);
