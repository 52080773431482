import React from 'react';

import { Box, Grid, Paper, styled, Typography } from '@mui/material';
// import Popover from './ImagePopover.jsx';

const BColor = '#203a55'; // Background Color
const BTColor = '#48a7f2'; // Blue Text Color

const Item = styled(Paper)(() => ({
  // padding: '5%',
  textAlign: 'Left',
  // height: '150px', //`str${MinH / 3} + 'px'`,
  width: '100%',
  backgroundColor: BColor,
  color: 'white',
}));

const MinH = 400;

import Background1 from '../../../assets/img/screenshots/1repodetails.webp';
import Background2 from '../../../assets/img/screenshots/2repodetailstimerange.webp';
import Background3 from '../../../assets/img/screenshots/3pickdayuserdetails.webp';
import Background4 from '../../../assets/img/screenshots/4starsoverview.webp';
import Background5 from '../../../assets/img/screenshots/5suboverview.webp';

// import Button from '../../modules/CustomButtons/Button';

const ImageGallery = () => {
  return (
    <div>
      <Typography
        variant="h2"
        component="div"
        textAlign={'center'}
        lineHeight={'3em'}
        color={BTColor}
      >
        Features
      </Typography>
      <Grid container spacing={2} alignItems="stretch" sx={{ padding: '10px' }}>
        <Grid item xs={12}>
          <Item>
            <h5 style={{ color: BTColor }}>Repository Details</h5>
            Detailed information about a repository - summary information
            (issues, pull requests, forks) and daily totals of user activity
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Box
            elevation={0}
            sx={{
              minHeight: `${MinH}` + 'px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `url(${Background1})`,
            }}
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <h5 style={{ color: BTColor }}>Pick a Timeframe</h5>
            Detailed information on a specific repository, graphs of subscribers
            and stargazers, and even details of users
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Box
            elevation={0}
            sx={{
              minHeight: `${MinH}` + 'px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `url(${Background2})`,
            }}
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <h5 style={{ color: BTColor }}>User details</h5>
            Select a day to show new users, and as well as those that unfollowed
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Box
            elevation={0}
            sx={{
              minHeight: `${MinH}` + 'px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `url(${Background3})`,
            }}
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <h5 style={{ color: BTColor }}>Overview of New Stargazers</h5>
            Show Details of new Stargazers for all the Repositories you track
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Box
            elevation={0}
            sx={{
              minHeight: `${MinH}` + 'px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `url(${Background4})`,
            }}
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <h5 style={{ color: BTColor }}>Overview of New Subscribers</h5>
            Show Details of new Subscribers for all the Repositories you track
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Box
            elevation={0}
            sx={{
              minHeight: `${MinH}` + 'px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `url(${Background5})`,
            }}
          ></Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageGallery;
