import React from 'react';
import { Link } from 'react-router-dom';

import { Fade, Button, Menu, MenuItem, Divider } from '@mui/material';
import { LOGOUT } from '../../state/constants/actionTypes.jsx';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        style={{ color: 'white', float: 'right' }}
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* <MenuItem
          component={Link}
          onClick={handleClose}
          to="/stargazeroverview"
          color="inherit"
          underline="none"
        >
          Stars Overview
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/subscriberoverview"
          color="inherit"
          underline="none"
        >
          Subs Overview
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/Profile"
          // selected={'/profile' == window.location.pathname}
          color="inherit"
          underline="none"
        >
          Profile Settings
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/reposettings"
          color="inherit"
          underline="none"
        >
          Repo Settings
        </MenuItem>
        <Divider /> */}
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/collections"
          color="inherit"
          underline="none"
        >
          Collections
        </MenuItem>
        {/* <MenuItem
          component={Link}
          onClick={handleClose}
          to="/lookuprepo"
          color="inherit"
          underline="none"
        >
          Lookup Repo
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/lookupuser"
          color="inherit"
          underline="none"
        >
          Lookup User
        </MenuItem> */}
        <Divider />
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/about"
          color="inherit"
          underline="none"
        >
          About
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to="/contact"
          color="inherit"
          underline="none"
        >
          Contact Us
        </MenuItem>
        <MenuItem onClick={props.onClickLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(FadeMenu);
