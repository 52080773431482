import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PolicyPageStyle from './PolicyPageStyle.jsx';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import {
  TERMSOFSERVICE_PAGE_LOADED,
  TERMSOFSERVICE_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: TERMSOFSERVICE_PAGE_LOADED }),
  onUnload: () => dispatch({ type: TERMSOFSERVICE_PAGE_UNLOADED }),
});

const TermsOfService = ({ classes, onLoad, onUnload }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  useEffect(() => {
    document.title = 'GitGenius - Terms of Service';
    window.gtag('event', 'Page Read', {
      event_category: 'tos',
      event_label: 'tos',
    });
  }, []);

  return (
    <main className={classes.content}>
      <Helmet>
        <title>
          GitGenius - Analyze your Repos, Followers, Stargazers, Starred Users,
          as well as your Subscribers
        </title>
        <meta
          name="description"
          content="GitGenius respects your privacy and is committed to protecting your
          Personal Information (any information that relates to an identified
          or identifiable individual)"
        />
        <link
          rel="canonical"
          href="https://www.gitgenius.co/terms_of_service"
        />
      </Helmet>
      <div className={classes.toolbar} />
      <Container>
        <h1 className="western">GitGenius Website Terms of Service</h1>
        <p>
          <em>Last updated: April 14th, 2024</em>
        </p>
        <p>
          Your access to and use of GitGenius is subject to the following terms
          and conditions and all applicable laws.
        </p>
        <p>
          <strong>Acceptance of Terms.</strong>
          <br />
          GitGenius (&quot;GitGenius&quot; or &quot;us&quot; or &quot;we&quot;)
          welcomes you to gitgenius.co and related sub-domains (the
          &quot;Website&quot;). By accessing and using the Website, you accept
          the following terms and conditions, without limitation or
          qualification. Unless otherwise stated, the contents of the Website
          including, but not limited to, the text and images contained herein
          and their arrangement are the property of GitGenius. All trademarks
          used or referred to in this Website are the property of their
          respective owners. Nothing contained in the Website shall be construed
          as conferring by implication or otherwise, any license or right to any
          copyright, patent, trademark or other proprietary interest of
          GitGenius or any third party.
        </p>
        <p>
          If you do not agree to these Terms of Service, do not use the Website.
          We can change these Terms of Service at any time without any notice to
          you. It is your responsibility to review these Terms of Service from
          time to time for any changes as it creates a binding legal agreement
          between you and GitGenius. If you use the Website after we have
          changed any of the Terms of Service, you are agreeing to all of the
          changes.
        </p>
        <p>
          <strong>Account; Passwords, Security.</strong>
          <br />
          You may need to set up an account in order to use some of the features
          of the Website. You may not use a third parties account without
          permission. When you are setting up your account, you must give us
          accurate and complete information. This means that you cannot set up
          an account using a name or contact information that does not apply to
          you, and you must provide accurate and current information on all
          registration forms that are part of the Website. You have complete
          responsibility for your account and everything that happens on your
          account. This means you need to be careful with your password. If you
          find out that someone is using your account without your permission,
          you must let us know immediately. You may not transfer your account to
          someone else. We are not liable for any damages or losses caused by
          someone using your account without your permission. However, if we (or
          anyone else) suffer any damage due to the unauthorized use of your
          account, you may be liable.
        </p>
        <p>
          <strong>Personal Use Only.</strong>
          <br />
          We are making the Website available to you for your information and
          personal use only. You may not (and you agree not to) use, copy,
          distribute, transmit, broadcast, sell, or do anything else with the
          Website for any other purpose.
        </p>
        <p>
          <strong>User Content.</strong>
          <br />
          You are solely responsible for any User Content you post to the
          Website, and the consequences of posting or publishing it. By
          &quot;User Content&quot;, we mean any Content you post to the Website.
          &quot;Content&quot; means information, data, text, software, music,
          sound, photos, graphics, videos, messages, tags, interactive features,
          or any other materials. When we say &quot;post&quot;, we include
          posting, uploading, sharing, submitting or otherwise providing User
          Content in any manner in connection with the Website.
        </p>
        <p>
          Unless expressly stated otherwise herein, any User Content submitted
          by you through the Website shall be deemed non-confidential and non
          proprietary. You represent that you have the lawful right to submit
          such information and agree that you will not submit any information
          unless you are legally entitled to do so. Because of the open nature
          of the Internet, we recommend that you not submit information you
          consider confidential.
        </p>
        <p>
          GitGenius does not accept unauthorized idea submissions outside of
          established business relationships. To protect the interests of our
          current clients and ourselves, we must treat the issue of such
          submissions with great care. Importantly, without a clear business
          relationship, GitGenius cannot and does not treat any such submissions
          in confidence. Accordingly, please do not communicate unauthorized
          idea submissions to GitGenius through the Website. Any ideas disclosed
          to GitGenius outside a pre-existing and documented confidential
          business relationship are not confidential and GitGenius may therefore
          develop, use and freely disclose or publish similar ideas without
          compensating you or accounting to you. GitGenius will make every
          reasonable effort to return or destroy any unauthorized idea
          submissions without detailed review of them. However, if a review is
          necessary in GitGenius’ sole discretion, it will be with the
          understanding that GitGenius assumes no obligation to protect the
          confidentiality of your idea or compensate you for its disclosure or
          use. By submitting an idea or other detailed submission to GitGenius
          through the Website, you agree to be bound by the terms of this stated
          policy.
        </p>
        <p>
          <strong>Restrictions on User Content and Your Conduct.</strong>
          <br />
          You may not:
        </p>
        <ul>
          <li>
            <p>use our Website for any illegal purpose;</p>
          </li>
          <li>
            <p>
              submit User Content that you do not have the right to submit,
              unless you have the owners permission; this includes material
              covered by someone elses copyright, patent, trade secret, privacy,
              publicity, or any other proprietary right;
            </p>
          </li>
          <li>
            <p>
              forge headers or manipulate other identifiers in order to disguise
              the origin of any User Content you submit;
            </p>
          </li>
          <li>
            <p>
              submit any User Content that contains lies, falsehoods or
              misrepresentations that could damage us or anyone else;
            </p>
          </li>
          <li>
            <p>
              submit User Content that is illegal, obscene, defamatory,
              libelous, threatening, pornographic, harassing, hateful, racially
              or ethnically offensive, or encourages conduct that would be
              considered a criminal offense, give rise to civil liability,
              violate any law, or is otherwise inappropriate;
            </p>
          </li>
          <li>
            <p>
              transmit any unsolicited or unauthorized advertising, promotional
              materials, junk mail, spam, chain letters, pyramid schemes, or any
              other form of solicitation;
            </p>
          </li>
          <li>
            <p>
              impersonate anyone else or lie about your affiliation with another
              person or entity;
            </p>
          </li>
          <li>
            <p>
              use meta tags or any other &quot;hidden text&quot; utilizing any
              of our or our suppliers product names or trademarks;
            </p>
          </li>
          <li>
            <p>
              upload, launch, post, email or transmit any material (including
              any bot, worm, scripting exploit or computer virus) that is likely
              to harm or corrupt our Website, or harm or corrupt our or anyone
              elses computer systems, or data;
            </p>
          </li>
          <li>
            <p>
              use our Website to harm minors in any way, including posting User
              Content that violates child pornography laws, child sexual
              exploitation laws, or any other laws protecting children;
            </p>
          </li>
          <li>
            <p>
              collect or gather other peoples personal information (including
              account information) from our Website;
            </p>
          </li>
          <li>
            <p>
              submit User Content which disparages us or our partners, vendor or
              affiliates; or
            </p>
          </li>
        </ul>
        <p>
          We have the sole right, but not necessarily the obligation, to delete
          at any time any User Content that violates these rules or that we
          believe to be inappropriate for any reason.
        </p>
        <p>
          <strong>We are Not Responsible for User Content.</strong>
          <br />
          We generally do not review any of the User Content posted by our
          users. We do not endorse any User Content or support any views,
          opinions, recommendations, or advice that may be in user submissions.
          User Content comes from a variety of sources, and we make no promises
          about the reliability of any source or the accuracy, usefulness,
          safety, or intellectual property rights of any user submission. You
          may be offended by User Content that you see on the Website. You may
          find some of it to be inaccurate, offensive, indecent, or
          objectionable. However, you agree not to hold us responsible in any
          way for your use of our Website, including your exposure to User
          Content.
        </p>
        <p>
          <strong>Proprietary Rights.</strong>
          <br />
          The Website and the content provided in the Website, including, but
          not limited to, graphic images, audio, video, html code, buttons, and
          text, may not be copied, reproduced, republished, uploaded, posted,
          transmitted, or distributed in any way, without the prior written
          consent of GitGenius, except that you may download, display, and print
          one copy of the materials on any single computer solely for your
          personal, non- commercial use, provided that you do not modify the
          material in any way and you keep intact all copyright, trademark, and
          other proprietary notices.
        </p>
        <p>
          <strong>Links to Other Sites.</strong>
          <br />
          The information provided on the Website is in most cases free of
          charge and for informational purposes only, and does not create a
          business or professional services relationship between you and
          GitGenius. Links on the Website may lead to services or sites not
          operated by GitGenius. No judgment or warranty is made with respect to
          such other services or sites and GitGenius takes no responsibility for
          such other sites or services. A link to another site or service is not
          an endorsement of that site or service. Any use you make of the
          information provided on the Website, or any site or service linked to
          by the Website, is at your own risk.
        </p>
        <p>
          <strong>Warranty Disclaimer.</strong>
          <br />
          The Website and its contents are provided “as is” and GitGenius makes
          no representation or warranty of any kind with respect to the Website
          or any site or service accessible through the Website. GitGenius
          expressly disclaims all express and implied warranties including, but
          not limited to, the implied warranties of merchantability, fitness for
          a particular purpose, title, and non-infringement. In no event will
          GitGenius be liable to any party for any direct, indirect, incidental,
          special, exemplary, consequential, or other damages (including, but
          not limited to, lost profits, business interruption, loss of programs
          or data) without regard to the form of action and whether in contract,
          tort, negligence, strict liability, or otherwise, arising out of or in
          connection with the Website, any content on or accessed through the
          Website or any site service linked to, or any copying, displaying, or
          use thereof.
        </p>
        <p>
          <strong>Termination and Suspension.</strong>
          <br />
          We may terminate or suspend the Website or any part of the Website,
          terminate or suspend your use of the Website, block any IP address, or
          remove any of your User Content at any time without cause without any
          liability to you.
        </p>
        <p>
          Further, we may terminate or suspend your permission to use the
          Website immediately and without notice upon any violation of these
          Terms of Service, your failure to pay any fees when due, upon the
          request of law enforcement or government agencies, for extended
          periods of inactivity, for unexpected technical issues or problems or
          for engagement by you in fraudulent or illegal activities. If we
          terminate your use of the Website for any of these reasons or
          otherwise for cause, we will not refund any fees you may have paid,
          whether for access to the Website or for Software (if applicable).
        </p>
        <p>
          Upon any termination we may delete your account, passwords and User
          Content, and we may bar you from further use of the Website. You
          understand that we may also continue to make your User Content
          available on the Website even if your use of the Website is terminated
          or suspended. You agree that we will have no liability to you or any
          third party for termination of your account or access to the Website.
        </p>
        <p>
          <strong>General Terms.</strong>
          <br />
          GitGenius maintains the Website in California, U.S.A. and you agree
          that these Terms of Service and any legal action or proceeding
          relating to the Website shall be governed by the laws of the State of
          California without reference to its choice of law rules. If you
          attempt to bring any legal proceedings against GitGenius you
          specifically acknowledge that GitGenius is free to choose the
          jurisdiction of our preference as to where such action against us may
          be held. As you have agreed by using the Website to choose the laws of
          the State of California to govern any such proceedings, we will
          probably choose to defend any such action in California and we can
          make this decision entirely as it suits us, without regard to where in
          the world you are located, or from where in the world you visited the
          Website. You are responsible for complying with the laws of the
          jurisdiction from which you are accessing the Website and you agree
          that you will not access or use the information on the Website in
          violation of such laws.
        </p>
      </Container>
    </main>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(PolicyPageStyle)(TermsOfService));
