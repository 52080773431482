import React from 'react';

// https://developers.google.com/search/docs/fundamentals/seo-starter-guide

import { Helmet } from 'react-helmet-async';

export const Seo = ({ title, desc, name }) => {
  var canonical = 'https://www.gitgenius.co/repos/' + name;
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonical} data-rh="true" />
      <meta name="description" content={desc} data-rh="true" />
      {/* <meta property="og:type" content={type} /> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta name="twitter:creator" content={name} data-rh="true" />
      {/* <meta name="twitter:card" content={type} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
    </Helmet>
  );
};
