import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import { Divider, Link } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ABOUT_PAGE_LOADED,
  ABOUT_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';
import AboutPageStyle from './AboutPageStyle.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: ABOUT_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ABOUT_PAGE_UNLOADED }),
});

const About = ({ classes, onLoad, onUnload }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    window.gtag('event', 'Page About', {
      event_category: 'About Page',
      event_label: 'About Page',
    });

    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  document.title = 'GitGenius - About Us';

  return (
    <main className={classes.content}>
      <Helmet>
        <title>
          About GitGenius - The audience, the problem, and the opportunity
        </title>
        <meta name="description" content="GitGenius - About" />
        <link rel="canonical" href="https://www.gitgenius.co/about" />
      </Helmet>
      <div className={classes.toolbar} />
      <div className={classes.container}>
        <h1 className="western">
          <span style={{ color: '#34a853' }}>Git</span>
          <span style={{ color: '#4285f4' }}>Genius</span> - The Why, What, and
          How
        </h1>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
        <h2>The Audience </h2>
        <ul style={{ fontSize: '1.5em' }}>
          <li>
            GitHub has over 100+ million users and 28 million public
            repositories
            <Link
              style={{ verticalAlign: 'super' }}
              href="https://en.wikipedia.org/wiki/GitHub"
              target="_blank"
            >
              <OpenInNewIcon fontSize="small" />
            </Link>
          </li>
          <li>Gitlab has over 30 million users</li>
          <li>
            Open-Source companies use GitHub to nurture/grow their audience, and
            to monetize that audience
          </li>
          <li>
            Users of GitHub use it to communicate with other users, find new
            projects, build a brand, and participate in the community
          </li>
        </ul>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
        <h2>The Problem</h2>
        <ul style={{ fontSize: '1.5em' }}>
          <li>
            Challenging for users to navigate, or find, repositories that may be
            compelling based on their interests
          </li>
          <li>
            No ability for repository owners to analyze their audience or their
            competition
          </li>
        </ul>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
        <h2>The Opportunity</h2>
        <p style={{ fontSize: '1.5em' }}>
          Provide tools to increase monetization and usefulness of the Git(Hub,
          Lab) ecosystems
        </p>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
        <h2>The How</h2>
        <ul style={{ fontSize: '1.5em' }}>
          <li>
            Continuously monitor GitHub repositories for both new and
            unsubscribed Users - both Stargazers (users who Star a repository)
            and Followers (who Subscribe/Watch a repository)
          </li>
          <li>Provide detailed view into subscribed users</li>
          <li>
            Provide analytics to users (related repositories, etc.)
            <li>
              Continuously monitor GitHub Users As they Subscribe, Unsubscribe
              to repositories
            </li>
          </li>
          <li>
            Provide recommendations of related repositories Provide Analytics to
            Repository Owners on their audience Provide Competitive information
            to Repository Owners
          </li>
        </ul>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
        <Link href="/repos/kubernetes/kubernetes">
          <h2>The Example - A Repository that is being analyzed </h2>
        </Link>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
        <h2>The Stack</h2>
        <p style={{ fontSize: '1.5em' }}>
          Each component (UI, API Server, Backend) runs on separate container
          for scalability:
        </p>
        <ul style={{ fontSize: '1.5em' }}>
          <li>
            WebUI - Served via Nginx proxy, written in React (working on
            React-Native for Mobile)
          </li>
          <li>API Server & Middleware - Golang</li>
          <li>API calls can exposed for future developer UI</li>
          <li>Data Store - ElasticSearch (currently v7, working on v8)</li>
          <li>
            Backend:
            <ul id="backend">
              <li>RabbitMQ - Queuing for processing users & repopositories</li>
              <li>
                Golang - Multithreaded (Worker Pools, GoRoutines)
                publishers/consumers to process messages off queue(s)
              </li>
            </ul>
          </li>
          <li>
            Backend:
            <ul id="backend">
              <li>RabbitMQ - Queuing for processing users & repopositories</li>
              <li>
                Golang - Multithreaded (Worker Pools, GoRoutines)
                publishers/consumers to process messages off queue(s)
              </li>
            </ul>
          </li>
        </ul>
        <Divider style={{ marginTop: '2em', marginBottom: '2em' }} />
      </div>
    </main>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AboutPageStyle)(About));
