import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from './DragHandle.jsx';
// import styled from 'styled-components';
import { styled as muistyled } from '@mui/system';

import { RemoveHandle } from './RemoveHandle.jsx';

// DragginRow - when table row is dragged this style is applied.
const MuiDraggableRow = muistyled('td')({
  background: 'rgba(127, 207, 250, 0.3',
});

// const DraggingRow = styled.td`
//   background: rgba(127, 207, 250, 0.3);
// `;

const MuiTableData = muistyled('td')({
  '&:first-of-type': {
    width: '3ch',
    minWidth: '15ch',
    verticalAligh: 'middle',
    height: '2.5em',
  },
  '&:last-of-type': {
    width: '3ch',
    minWidth: '15ch',
    verticalAligh: 'middle',
  },
});

export const DraggableTableRow = ({ row, onRemove }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    // background: 'red',
  };
  const { key, ...restRowsProps } = row.getRowProps();
  // console.log('key r = ', key);
  // console.log('restRowsProps = ', restRowsProps);
  // console.log('DraggableTableRow');
  return (
    <tr key={key} ref={setNodeRef} style={style} {...restRowsProps}>
      {isDragging ? (
        <MuiDraggableRow colSpan={row.cells.length}>&nbsp;</MuiDraggableRow>
      ) : (
        row.cells.map((cell, i) => {
          const { key, ...restCellProps } = cell.getCellProps();
          // console.log('key drag = ', key);
          // console.log('cell.getCellProps() = ', cell.getCellProps());
          if (i === 0) {
            return (
              <MuiTableData key={key} {...restCellProps}>
                <DragHandle {...attributes} {...listeners} />
                {cell.render('Cell')}
              </MuiTableData>
            );
          }
          return (
            <MuiTableData key={key} {...restCellProps}>
              <span>{cell.render('Cell')}</span>
              <RemoveHandle onRemove={onRemove} cell={cell} />
            </MuiTableData>
          );
        })
      )}
    </tr>
  );
};
