import React, { useEffect } from 'react';
// import { hydrateRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { APP_LOAD, REDIRECT } from './state/constants/actionTypes.jsx';
import agent from './agentAxios.jsx';
import MyRoutes from './routes/Routes.jsx';
import './assets/fonts.css';
import './assets/stylesheet.css';
import TagManager from 'react-gtm-module';
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import darkScrollbar from '@mui/material/darkScrollbar';
import ErrorBoundary from './ErrorBoundary.jsx';

const font = 'Noto Sans';

const mapStateToProps = (state) => ({
  appLoaded: state.common.appLoaded,
  appName: state.common.appName,
  showDrawer: state.common.showDrawer,
  currentUser: state.common.currentUser,
  redirectTo: state.common.redirectTo,
  reactGaInitialised: state.common.reactGaInitialised,
  isLoggedIn: state.common.isLoggedIn,
  useDarkMode: state.common.useDarkMode,
  memberLevel: state.common.memberLevel,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
});

const tagManagerArgs = {
  gtmId: 'GTM-KCGRN7K',
  dataLayer: {
    userId: '001',
    userProject: 'projectgitgenius',
  },
};

const App = ({ onLoad, isLoggedIn }) => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const token = window.localStorage.getItem('jwt');
    if (token) {
      agent.setToken(token);
    }
    onLoad(token ? agent.Auth.current() : null, token);
  }, [onLoad]);

  const theme =
    window.localStorage.getItem('useDarkMode') === 'false'
      ? createTheme({
          typography: {
            fontFamily: font,
          },
          palette: {
            mode: 'light',
          },
        })
      : createTheme({
          typography: {
            fontFamily: font,
          },
          palette: {
            mode: 'dark',
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: darkScrollbar(),
            },
          },
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              mm: 700,
              md: 900,
              lg: 1200,
              xl: 1536,
            },
          },
        });

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MyRoutes isLoggedIn={isLoggedIn} />
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};

// hydrateRoot(App, document.getElementById('App'));

App.propTypes = {
  onLoad: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
