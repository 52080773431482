import axios from 'axios'; // Import Axios
import APIServerRoot from './modules/UIComponents/ApiEnvironment.jsx';

const API_ROOT = APIServerRoot();

const responseBody = (res) => res.data; // Change res.body to res.data for Axios

let token = null;
// const tokenPlugin = (req) => {
//   console.log('Axios tokenPlugin - req = ', req);
//   if (token) {
//     req.headers['Authorization'] = `Bearer ${token}`; // Modify authorization header for Axios
//   }
// };

axios.interceptors.request.use(function (config) {
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

const requests = {
  del: (url) => axios.delete(`${API_ROOT}${url}`).then(responseBody), // Change to axios.delete
  get: (url) => axios.get(`${API_ROOT}${url}`).then(responseBody), // Change to axios.get
  put: (url, body) =>
    axios
      .put(`${API_ROOT}${url}`, body)
      .then(responseBody)
      .then(console.log('axios put - responseBody')), // Change to axios.put
  post: (url, body) => axios.post(`${API_ROOT}${url}`, body).then(responseBody), // Change to axios.post
};

const Auth = {
  current: () => requests.get('/user'),
  login: (email, password) =>
    requests.post('/user/login', { user: { email, password } }),
  loginreset: (email) => requests.post('/user/loginreset', { user: { email } }),
  resetlink: (url) => requests.post('/user/resetlink', { url }),
  register: (invitationcode, firstname, lastname, username, email, password) =>
    requests.post('/user/new', {
      user: { invitationcode, firstname, lastname, username, email, password },
    }),
  save: (user) => requests.put('/user', { user }),
  search: (data) => requests.post('/search/userintimeframe', data),
};

const Sendmail = {
  send: (data) => requests.get('/sendmail', data),
};

const ValidateRepo = {
  send: (data) => requests.get('/validaterepo', data),
};

export default {
  Auth,
  Sendmail,
  ValidateRepo,
  setToken: (_token) => {
    token = _token;
  },
};
