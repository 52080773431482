import React from 'react';
// import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Header from '../modules/MenuBars/MenuBars.jsx';
import Footer from '../modules/Footer/Footer.jsx';
//Public Routes

import RouteList from '../routes/RouteList.jsx';

const MyRoutes = () => (
  <div>
    <Header />
    {RouteList}
    <Footer />
  </div>
);

export default MyRoutes;
