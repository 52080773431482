import React from 'react';

import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import NumberOfDaysPicker from './NumberOfDaysPicker.jsx';
import { Box, Button, Grid } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { addDays, compareAsc, endOfDay, parseISO, compareDesc } from 'date-fns';

export default function RepoDateContainer(
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  daysTotal,
  daysFromCreate,
  detaildata
) {
  if (detaildata.length == 0) {
    return;
  } else {
    var createdat = endOfDay(parseISO(detaildata.created_at));
    // console.log('createdat = ', createdat);
    // TypeError: undefined is not an object (evaluating 'e.split')
    return (
      <Grid container spacing={0}>
        <Grid item xs={4} sm={3} align="right">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              disableFuture
              label="Start Date"
              value={startDate}
              minDate={createdat}
              onChange={(newValue) => {
                if (compareAsc(new Date(newValue), new Date(endDate)) == -1) {
                  setEndDate(addDays(newValue, -30));
                }
                setStartDate(newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={8} sm={6} pt={0} pb={2}>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Button
                sx={{
                  minHeight: 56,
                }}
                variant="outlined"
                onClick={() => {
                  var now = endOfDay(new Date());
                  var start = endOfDay(new Date(startDate));
                  if (compareAsc(now, addDays(start, daysTotal)) != -1) {
                    setStartDate(addDays(startDate, daysTotal));
                    setEndDate(addDays(endDate, daysTotal));
                  } else {
                    setStartDate(now);
                    setEndDate(addDays(now, -1 * daysTotal));
                  }
                }}
              >
                <ArrowBackIosNewIcon />
              </Button>
            </Grid>
            <Grid item>
              <NumberOfDaysPicker
                startDate={startDate}
                daysTotal={daysTotal}
                endDate={endDate}
                setEndDate={setEndDate}
                daysFromCreate={daysFromCreate}
              />
            </Grid>
            <Grid item>
              <Button
                sx={{
                  minHeight: 56,
                }}
                variant="outlined"
                onClick={() => {
                  daysTotal = -1 * daysTotal;
                  // var createdat = endOfDay(parseISO(detaildata.created_at));
                  if (
                    compareDesc(createdat, addDays(endDate, daysTotal)) == 1
                  ) {
                    setStartDate(addDays(startDate, daysTotal));
                    setEndDate(addDays(endDate, daysTotal));
                  } else {
                    setStartDate(addDays(createdat, -1 * daysTotal));
                    setEndDate(createdat);
                  }
                }}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={3} align="left">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <MobileDatePicker
                disabled
                disableOpenPicker
                label="End Date (calculated)"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
              />
            </Box>
          </LocalizationProvider>
        </Grid>
      </Grid>
    );
  }
}
