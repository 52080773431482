import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Toolbar,
  Tooltip,
  useScrollTrigger,
} from '@mui/material';
import classNames from 'classnames';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/DashboardSharp';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/img/headerlogo.png';
import { CookieConsent } from 'react-cookie-consent'; //https://www.npmjs.com/package/react-cookie-consent
import { LOGOUT } from '../../state/constants/actionTypes.jsx';
import RepoMenuItems from './RepoMenuItems.jsx';
import MenuBarStyles from './MenuBarStyles.jsx';
import { LoggedOutView } from './MenuLogInOut.jsx';
import UserMenu from './UserFadeMenu.jsx';
import SettingMenu from './SettingSubMenu.jsx';
import ToolsMenu from './ToolsSubMenu.jsx';

const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

const LoggedInView = (props) => {
  const username = props.currentUser.username;
  return (
    <ul className="nav navbar-nav" style={{ paddingTop: '17px' }}>
      <li className="nav-item" style={{ color: 'white', float: 'right' }}>
        <div style={{ display: 'flex', float: 'right' }}>
          <div style={{ marginTop: '5px' }}> {username}</div>
          <UserMenu />
        </div>
      </li>
    </ul>
  );
};

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { classes, isLoggedIn, showDrawer, theme } = props;

  var GitColor = '#34a853';
  var GeniusColor = '#4285f4';
  if (theme.palette.mode === 'light') {
    GitColor = 'white';
    GeniusColor = 'white';
  }
  var menuButtonClass = classes.menuButton;
  var ChevronsClass = classes.Chevrons;

  if (!showDrawer) {
    menuButtonClass = classes.menuButtonLgDrawer;
    ChevronsClass = classes.ChevronsLgDrawer;
  }

  return (
    <div>
      <CssBaseline />
      <CookieConsent
        style={{
          alignItems: 'baseline',
          zIndex: '9999',
        }}
        buttonStyle={{
          background: '#009688',
          color: 'white',
        }}
      >
        <p>
          We serve cookies on this site to analyze traffic, remember your
          preferences, and optimize your experience.
        </p>
        <p>
          You can review our Cookie Policy{' '}
          <a href="/cookie_policy" style={{ textDecoration: 'underline' }} />
        </p>
      </CookieConsent>
      <HideOnScroll>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar disableGutters={!open}>
            {isLoggedIn ? (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                className={classNames(menuButtonClass)}
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <IconButton className={classes.navpadding} size="large" />
            )}
            <Grid container className={classes.containernomargin} spacing={0}>
              <Grid item xs={4}>
                <Link to="/" className="navbar-brand">
                  <img
                    src={Logo}
                    width="50px"
                    className="navbar-brand"
                    alt="logo"
                  />
                  <span style={{ color: GitColor }}>Git</span>
                  <span style={{ color: GeniusColor }}>Genius</span>
                </Link>
              </Grid>
              <Grid item xs={8}>
                {isLoggedIn ? (
                  <LoggedInView currentUser={props.currentUser} />
                ) : (
                  <LoggedOutView />
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {props.showDrawer || open ? (
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerToggle} size="large">
              {open ? (
                <ChevronLeftIcon className={ChevronsClass} />
              ) : (
                <ChevronRightIcon className={ChevronsClass} />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              component={Link}
              key="Overviewstars"
              to="/stargazeroverview"
              onClick={handleDrawerClose}
              selected={'/stargazeroverview' === window.location.pathname}
            >
              <ListItemIcon>
                <Tooltip title="Stargazer Overview Dashboard">
                  <DashboardIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Stargazer Overview" />
            </ListItem>
            <ListItem
              component={Link}
              key="Overviewsubs"
              to="/subscriberoverview"
              onClick={handleDrawerClose}
              selected={'/stargazeroverview' === window.location.pathname}
            >
              <ListItemIcon>
                <Tooltip title="Subscriber Overview Dashboard">
                  <DashboardIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Subscriber Overview" />
            </ListItem>
            <Divider />
            <SettingMenu handleDrawerClose={handleDrawerClose} />
            <ToolsMenu handleDrawerClose={handleDrawerClose} />
            <Divider />
            <RepoMenuItems
              Repos={props.currentUser}
              handleDrawerClose={handleDrawerClose}
            />
          </List>
        </Drawer>
      ) : null}
      {props.children}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appName: state.common.appName,
    token: state.common.token,
    isLoggedIn: state.common.isLoggedIn,
    showDrawer: state.common.showDrawer,
    currentUser: state.common.currentUser,
    useDarkMode: state.common.useDarkMode,
    counter: state.common.viewChangeCounter,
    theme: state.theme, // assuming theme is provided by redux state
  };
};

Header.propTypes = {
  token: PropTypes.string,
  appName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  showDrawer: PropTypes.bool,
  useDarkMode: PropTypes.bool,
  currentUser: PropTypes.object,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  viewChangeCounter: PropTypes.number,
  children: PropTypes.node,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(MenuBarStyles, { withTheme: true })(Header));
