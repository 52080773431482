import CreateUniqueKey from '../../components/RepoSettings/HelperFunctions/CreateUniqueKey.jsx';

// RepoAddSelectedToData takes existing repos, and 'new' selected repos (from library)
// checks if each

const newRepo = (id, user, reponame) => {
  // console.log('newRepo id = ', id);
  return {
    id: `repo-${id}`,
    user: `${user}`,
    reponames: [`${reponame}`],
  };
};

function prepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export default function RepoAddSelectedToData(
  repodata,
  selectedrepos,
  setResponse
) {
  // console.log('RepoAddSelectedToData repodata = ', repodata.Repodata);
  // console.log('selectedrepos.length = ', selectedrepos.length);
  // Check if nothing selected
  if (selectedrepos.length == 0) {
    setResponse('Nothing selected');
    return;
  }
  // const [array, setArray] = useState([]);
  const array = [];
  for (let s = 0; s < selectedrepos.length; s++) {
    var repoinslected =
      selectedrepos[s].original.RepoUser +
      '/' +
      selectedrepos[s].original.RepoName;
    var found = false;
    for (let r = 0; r < repodata.Repodata.length; r++) {
      var repoinlist =
        repodata.Repodata[r].user + '/' + repodata.Repodata[r].reponames[0];

      // console.log('i = ', selectedrepos[s].original);
      if (repoinlist == repoinslected) {
        found = true;
        break;
      }
    }
    if (!found) {
      array.unshift(repoinslected);
    }
  }
  // console.log('RepoAddSelectedToData setData = ', repodata.setData);
  // console.log('RepoAddSelectedToData selectedrepos = ', selectedrepos);
  if (found && array.length == 0) {
    setResponse('Selected already in list');
    return;
  }
  for (let r = 0; r < array.length; r++) {
    var splittable = array[r].split('/');
    var ukey = CreateUniqueKey(repodata.Repodata);
    var newrepo = newRepo(ukey, splittable[0], splittable[1]);
    repodata.setData(prepend(newrepo, repodata.Repodata));
  }

  // console.log('new arrays are ', array);
  setResponse(array.length + ' added');
  return 'return Ok';
}
