import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PolicyPageStyle from './PolicyPageStyle.jsx';
import { Helmet } from 'react-helmet-async';

import {
  COOKIEPOLICY_PAGE_LOADED,
  COOKIEPOLICY_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: COOKIEPOLICY_PAGE_LOADED }),
  onUnload: () => dispatch({ type: COOKIEPOLICY_PAGE_UNLOADED }),
});

const CookiePolicy = ({ classes, onLoad, onUnload }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    window.gtag('event', 'Page Read', {
      event_category: 'Cookie Policy',
      event_label: 'Cookie Policy',
    });
    return () => {
      onUnload();
    };
  }, []);

  document.title = 'GitGenius - Cookie Policy';

  return (
    <main className={classes.content}>
      <Helmet>
        <title>
          GitGenius - Analyze your Repos, Followers, Stargazers, Starred Users,
          as well as your Subscribers
        </title>
        <meta
          name="description"
          content="GitGenius respects your privacy and is committed to protecting your Personal Information (any information that relates to an identified or identifiable individual)"
        />
        <link rel="canonical" href="https://www.gitgenius.co/cookie_policy" />
      </Helmet>
      <div className={classes.toolbar} />
      <div className={classes.container}>
        <h1 className="western">Cookie Policy</h1>
        <p>
          <em>April 14th, 2024</em>
        </p>
        <p>
          This Cookie Policy (“<strong>Policy</strong>”) explains how GitGenius,
          and subsidiaries (collectively, “<strong>GitGenius</strong>”, “
          <strong>we</strong>”, “<strong>us</strong>” or “<strong>our</strong>”)
          use cookies and similar technologies to recognize you when you visit
          one of our websites, including any other media form, media channel,
          mobile website, or mobile application related or connected thereto
          provided or officially sponsored by GitGenius, or any other GitGenius
          websites that link to this Policy (collectively, the “
          <strong>Websites</strong>”).
        </p>
        <p>
          This Policy explains what these technologies are and why we use them,
          as well as our users’ (“<strong>data subject</strong>”, “
          <strong>user</strong>”, “<strong>you</strong>” or “
          <strong>your</strong>”) rights to control our use of them.
        </p>
        <p>
          In some cases, we may use cookies to collect personal information, or
          that becomes personal information if we combine it with other
          information. In such cases, our Privacy Policy (
          <a href="https://www.GitGenius.co/privacy">
            https://www.GitGenius.co /privacy
          </a>
          ) will apply in addition to this Cookie Policy. We may request your
          consent to set cookies on your computer or mobile device as required
          by the General Data Protection Regulation (“<strong>GDPR</strong>”).
        </p>
        <h2>What are cookies?</h2>
        <p>
          Cookies are small text files that are stored on your computer or
          mobile device when you visit a website. Cookies are created when you
          visit a website that uses cookies to keep track of your movements
          within the site, help you resume where you left off, and any
          preferences. Websites store a corresponding file to the one that is
          set in your browser, and in this file websites track and keep
          information on your movements within the site, and any information you
          may voluntarily give while visiting the website, such as email
          address.
        </p>
        <h2>Why do we use cookies?</h2>
        <p>
          We use cookies for several reasons, such as to provide you with a
          consistent and customized experience on our Websites. Like most
          organizations, we also partner with selected third parties, listed
          below, for tracking and marketing purposes on our Websites; these
          cookies enable us to analyze and track the use of our Websites,
          determine the popularity of certain content and better understand
          online activity.
        </p>
        <p>
          You are encouraged to review the privacy policies of these third
          parties and contact them directly for responses to your questions.
        </p>
        <h3>Essential cookies</h3>
        <p>
          These are necessary to provide you with the services available through
          our Websites and to use some of its features, such as access to secure
          or restricted areas.
        </p>
        <p>
          <strong>Who serves these cookies:</strong>
        </p>
        <table style={{ width: '893', cellPadding: '3', cellSpacing: '0' }}>
          <tbody>
            {/* <col style={{ width: '129' }} />
            <col style={{ width: '287' }} />
            <col style={{ width: '459' }} /> */}
            <tr>
              <td style={{ width: '129', border: 'none', padding: '0in' }}>
                <p>
                  <b>Cookie Provider</b>
                </p>
              </td>
              <td style={{ width: '287', border: 'none', padding: '0in' }}>
                <p>
                  <b>Website URL:</b>
                </p>
              </td>
              <td style={{ width: '459', border: 'none', padding: '0in' }}>
                <p>
                  <b>Description</b>
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '129', border: 'none', padding: '0in' }}>
                <p>GitGenius</p>
              </td>
              <td style={{ width: '287', border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://www.gitgenius.co/">
                    https://www.gitgenius.co
                  </a>
                </p>
              </td>
              <td style={{ width: '459', border: 'none', padding: '0in' }}>
                <p>
                  We collect IP addresses and browser metadata, such as IP
                  addresses and device information
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '129', border: 'none', padding: '0in' }}>
                <p>Segment</p>
              </td>
              <td style={{ width: '287', border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://segment.com/">https://segment.com</a>
                </p>
              </td>
              <td style={{ width: '459', border: 'none', padding: '0in' }}>
                <p>
                  Central hub for capturing our interactions with our customers
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '129', border: 'none', padding: '0in' }}>
                <p>Google</p>
              </td>
              <td style={{ width: '287', border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://www.netlify.com/">https://www.google.com/</a>
                </p>
              </td>
              <td style={{ width: '459', border: 'none', padding: '0in' }}>
                <p>Netlify is our hosting provider</p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '129', border: 'none', padding: '0in' }}>
                <p>CloudFlare</p>
              </td>
              <td style={{ width: '287', border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://www.cloudflare.com/cookie-policy/">
                    https://www.cloudflare.com/cookie-policy/
                  </a>
                </p>
              </td>
              <td style={{ width: '459', border: 'none', padding: '0in' }}>
                <p>CloudFlare provides DNS services</p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '129', border: 'none', padding: '0in' }}>
                <p>Youtube</p>
              </td>
              <td style={{ width: '287', border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://www.youtube.com/">
                    https://www.youtube.com/
                  </a>
                </p>
              </td>
              <td style={{ width: '459', border: 'none', padding: '0in' }}>
                <p>Used for embedding videos on our Websites</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>How to opt-out:</strong>
        </p>
        <p>
          Because these cookies are necessary for our Websites to function, you
          cannot opt-out. You can, however, block or delete them by changing
          your browser settings as described below under the heading “
          <strong>How can I control cookies?</strong>”
        </p>
        <h3 className="western">Functional cookies</h3>
        <p>
          Functional cookies provide a utility to the website, such as the
          ability to log in, or to get live support. Disabling any of these
          scripts will cause that functionality to become unavailable.
        </p>
        <p>
          Please also refer to “<strong>How can I control cookies?</strong>”
          section below.
        </p>
        <h3 className="western">Analytics cookies</h3>
        <p>
          These cookies are used to enhance the performance and functionality of
          our Websites, but are generally non-essential to their use. Without
          these cookies, certain functionality (like videos) may become
          unavailable.
        </p>
        <p>
          Analytics services enhance the performance and functionality of our
          Websites, and keep track of page traffic and user behavior while
          browsing the site. We use this data internally to improve the
          usability and performance of the site. Disabling any of these scripts
          makes it more difficult for us to understand how our site is being
          used, and slower to improve it.
        </p>
        <p>
          <strong>Who serves these cookies:</strong>
        </p>
        <table cellPadding="3" cellSpacing="2">
          <tbody>
            <tr>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  <b>Cookie Provider</b>
                </p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  <b>Website URL:</b>
                </p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  <b>Description</b>
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>Google Analytics</p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://analytics.google.com/analytics/web/">
                    https://analytics.google.com/analytics/web/
                  </a>
                </p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  Google Analytics is a popular service for tracking web
                  traffic. We use this data to tailor content to our users
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>LinkedIn Insight Tag</p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://www.linkedin.com/">
                    https://www.linkedin.com
                  </a>
                </p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  Allows us to gauge effectiveness of our LinkedIn campaigns
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>Heap</p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  <a href="https://heap.io/">https://heap.io/</a>
                </p>
              </td>
              <td style={{ border: 'none', padding: '0in' }}>
                <p>
                  Heap Analytics tracks web traffic. We use this data to tailor
                  content to our users
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>How to opt-out:</strong> To opt-out, please scroll to the
          bottom of the page on{' '}
          <a href="https://www.gitgenius.co/">https://www.gitgenius.co</a>
          and click on “<strong>Cookie Manager</strong>”. You can modify your
          preferences within the pop-up. Please also refer to “
          <strong>How can I control cookies?</strong>” section below.
        </p>
        <h2 className="western">Social networking cookies</h2>
        <p>
          By accessing social networks or using social features of our Websites,
          these social networking services or providers may set additional
          cookies, which may be used to track your browsing activity. We do not
          have control over these cookies. You are encouraged to review the
          relevant privacy and cookie policies for each respective social
          network provider to ensure you are comfortable with their data privacy
          practices. To opt-out, please refer to each respective social network
          provider. Additional resources may be found in the “
          <strong>How can I control cookies?</strong>” section of this policy.
        </p>
        <h2 className="western">What about other tracking technologies?</h2>
        <p>
          In addition to cookies, we use other tracking technologies like
          tracking pixels. A tracking pixel is an HTML code snippet which is
          loaded when a user visits a website or opens an email. It is useful
          for tracking user behavior and conversions. We use tracking pixels to
          monitor the traffic patterns of our users, to understand if you have
          clicked on an ad displayed on a third-party website, improve site
          performance, and to measure the success of email marketing campaigns.
          In many instances, these technologies are reliant on cookies to
          function properly, and so declining cookies will impair this
          functionality. Personal information collected using tracking pixels is
          stored and analyzed by us to optimize the delivery of our newsletters
          and emails, and to improve the relevance of the distributed content.
          Many email clients and web browsers support functionality to opt-out
          or prevent the use of these tracking mechanisms.
        </p>
        <h2 className="western">How can I control cookies?</h2>
        <p>
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie preferences by following the appropriate
          opt-out links provided in the cookie table above. You can set or amend
          your Internet browser controls to accept or refuse cookies. If you
          choose to reject cookies, you may still use our Websites, though your
          access to some functionality and areas of our Websites may be
          restricted. Please visit your browser’s help menu for information on
          how to opt-out of cookies. Tracking pixels may be refused by
          configuring the settings on your email client or web browser to
          prevent web beacons from being activated. Please refer to the
          documentation for your particular web browser, email client or device
          for additional information on whether it is supported and how to
          configure this setting. For additional information regarding our
          privacy practices and how we protect your personal information, please
          refer to our{' '}
          <a href="https://www.gitgenius.co/privacy">Privacy Policy</a>.
        </p>
        <h3 className="western">A special note on Do-Not-Track signals</h3>
        <p>
          Modern web browsers and mobile operating systems include a
          Do-Not-Track (“DNT”) feature; however, no uniform technology standard
          for recognizing and implementing DNT signals has been finalized.
          Therefore, we do not currently commit to responding to browsers’ DNT
          signals with respect to our Websites. If a universal standard for
          responding to DNT signals is adopted in the future, we will update
          this Cookie Policy to reflect such practices.
        </p>
        <h2 className="western">
          How often will you update this Cookie Policy?
        </h2>
        <p>
          We may update this Cookie Policy periodically to reflect changes to
          the cookies we use or for other operational, legal or regulatory
          reasons. Please review this Cookie Policy to stay informed about our
          use of cookies and related technologies and your choices.
        </p>
        <h3 className="western">Where can I get more information?</h3>
        <p>
          If you have any questions about our use of cookies or other
          technologies, please email us at{' '}
          <a href="mailto:support@gitgenius.co">support@gitgenius.co</a>
        </p>
      </div>
    </main>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(PolicyPageStyle)(CookiePolicy));
