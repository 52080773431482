import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import APIServerRoot from '../../modules/UIComponents/ApiEnvironment.jsx';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import UserTable from './RepoDetailUserTable.jsx';
// import UserDataGrid from './RepoDetailUserDataGrid.jsx';
import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

let isMounted = false; // note mutable flag

export default function StarRepoUserDetails(props) {
  // const [expanded, setExpanded] = React.useState('panel1');
  // const [progressbar, setProgressBar] = React.useState(false);
  var API_DETAILS = APIServerRoot() + '/search/getusersintimeperiod';
  const reponame = props.reponame;
  const detaildate = props.detaildate;
  const userfirstdata = props.userfirstdata;
  const setUserFirst = props.setUserFirst;
  const userlasttdata = props.userlastdata;
  const setUserLast = props.setUserLast;
  const starorsub = props.starorsub;
  const expanded = props.expanded;
  const setExpanded = props.setExpanded;
  const progressbar = props.progressbar;
  const setProgressBar = props.setProgressBar;
  var allAPICallsFinished = [false, false, false, false];

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const checkProgressBar = (allAPICallsFinished) => {
    if (
      allAPICallsFinished[0] == true &&
      allAPICallsFinished[0] == true &&
      allAPICallsFinished[0] == true &&
      allAPICallsFinished[0] == true
    ) {
      // console.log('All done with ProgressBar');
      setProgressBar(false);
      allAPICallsFinished = [false, false, false, false];
    }
  };

  useEffect(() => {
    isMounted = true; // note mutable flag
    // Check if 1) mounted, 2) if object is not empty, and 3) if last is not zero
    if (isMounted && Object.keys(detaildate).length > 0) {
      // console.log('2a detaildate = ', detaildate);
      setProgressBar(true);
      setUserFirst([]);
      let abortController = new AbortController();
      // {"post_data":"{\"Type\":\"stars\", \"Name\":\"facebook/react\", \"Start\":\"2022-09-17\", \"End\":\"2022-09-17\"}"}
      var post_data =
        `{"Type":"` +
        starorsub +
        `", "ForL": "first", "Name":"` +
        reponame.toLowerCase() +
        `", "Start":"` +
        detaildate.D +
        `", "End":"` +
        detaildate.D +
        `"}`;
      const doFetch = async () => {
        const axiosresponse = await axios({
          method: 'POST',
          url: API_DETAILS,
          data: { post_data },
        });
        if (axiosresponse.data == null || axiosresponse.data == 'Not Found') {
          setUserFirst([]);
        } else {
          setUserFirst(axiosresponse.data);
          setExpanded('userpanel');
          handleChange('panel');
        }
        if (starorsub == 'stars') {
          // console.log('First starorsub = ', starorsub);
          allAPICallsFinished[0] = true;
        } else {
          // console.log('First starorsub = ', starorsub);
          allAPICallsFinished[2] = true;
        }
        checkProgressBar(allAPICallsFinished);
      };
      doFetch();
      return () => {
        abortController.abort();
      };
    } else {
      setUserFirst([]); //SHould this be here? what about setUserFirst?
    }
  }, [detaildate]);

  useEffect(() => {
    isMounted = true; // note mutable flag

    // Check if 1) mounted, 2) if object is not empty, and 3) if last is not zero
    if (isMounted && Object.keys(detaildate).length > 0 && detaildate.L != 0) {
      // console.log('2b detaildate = ', detaildate.L);
      setProgressBar(true);
      setUserLast([]);
      // console.log('turned on progress bar');
      let abortController = new AbortController();
      // {"post_data":"{\"Type\":\"stars\", \"Name\":\"facebook/react\", \"Start\":\"2022-09-17\", \"End\":\"2022-09-17\"}"}
      var post_data =
        `{"Type":"` +
        starorsub +
        `", "ForL": "last", "Name":"` +
        reponame.toLowerCase() +
        `", "Start":"` +
        detaildate.D +
        `", "End":"` +
        detaildate.D +
        `"}`;
      const doFetch = async () => {
        const axiosresponse = await axios({
          method: 'POST',
          url: API_DETAILS,
          data: { post_data },
        });
        if (axiosresponse.data == null || axiosresponse.data == 'Not Found') {
          setUserLast([]);
        } else {
          setUserLast(axiosresponse.data);
          setExpanded('userpanel');
          handleChange('panel');
        }
        if (starorsub == 'stars') {
          // console.log('Last starorsub a = ', starorsub);
          allAPICallsFinished[1] = true;
        } else {
          allAPICallsFinished[3] = true;
        }
        checkProgressBar(allAPICallsFinished);
      };
      doFetch();
      return () => {
        abortController.abort();
      };
    } else {
      setUserLast([]); //SHould this be here? what about setUserFirst?
    }
  }, [detaildate]);

  if (userfirstdata.length == 0 && userlasttdata == 0) {
    return (
      <div>
        <Accordion
          expanded={expanded === 'userpanel'}
          onChange={handleChange('userpanel')}
        >
          <AccordionSummary
            aria-controls="userpaneld-content"
            id="userpaneld-header"
          >
            {starorsub == 'stars' && <Typography>Stargazer Details</Typography>}
            {starorsub == 'subs' && <Typography>Subscriber Details</Typography>}
            {progressbar && (
              <Box sx={{ width: '50%', marginTop: '10px', marginLeft: '10px' }}>
                <LinearProgress />
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              When a day is clicked above, details of the first seen, and last
              seen, `user`s will go here.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return (
      <div>
        <Accordion
          expanded={expanded === 'userpanel'}
          onChange={handleChange('userpanel')}
        >
          <AccordionSummary
            aria-controls="userpaneld-content"
            id="userpaneld-header"
          >
            {starorsub == 'stars' && <Typography>Stargazer Details</Typography>}
            {starorsub == 'subs' && <Typography>Subscriber Details</Typography>}
            {progressbar && (
              <Box sx={{ width: '50%', marginTop: '10px', marginLeft: '10px' }}>
                <LinearProgress />
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <p>Users for date {detaildate.D}</p>
            <p style={{ color: '#48A7F2' }}>
              First Seen Total is {userfirstdata.length}{' '}
            </p>
            {/* <UserDataGrid textcolor="first" data={userfirstdata} /> */}
            <UserTable textcolor="first" data={userfirstdata} />
            {/* <ul style={{ color: '#48A7F2' }}>
              {userfirstdata?.map((user, key) => {
                return (
                  <li key={key}>
                    {user.name} {user.login} {user.id} {user.email} {user.type}
                  </li>
                );
              })}
            </ul> */}
            <p style={{ color: '#FD704B' }}>
              Last Seen Total is {userlasttdata.length}{' '}
            </p>
            <UserTable textcolor="last" data={userlasttdata} />

            {/* <ul style={{ color: '#FD704B' }}>
              {userlasttdata?.map((user, key) => {
                return (
                  <li key={key}>
                    {user.id} {user.type}
                  </li>
                );
              })}
            </ul> */}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}
