// Using https://react-table.tanstack.com/docs/faq and
// Code from https://thewidlarzgroup.com/react-table-7/
// maybe this for axios call - https://stackoverflow.com/questions/54684255/how-do-you-make-axios-get-request-wait/54684366

import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import TableContainer from './TableContainer';
import axios from 'axios';
import APIServerRoot from '../UIComponents/ApiEnvironment.jsx';
import { CircularProgress } from '@mui/material';
import LinearWithValueLabel from '../../modules/UIComponents/ProgressBar.jsx';

const StarRepoTable = (props) => {
  var storeStarOverview = localStorage.getItem('staroverview');
  var storeStarTime = localStorage.getItem('startiime');

  const [stardata, setStarData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  var API_ROOT = APIServerRoot() + '/search/' + props.List;

  const post_data = JSON.stringify(props.Repolist);

  const e = new Date();
  var HoursUTC = 'Last ' + e.getUTCHours() + 'h, ' + e.getUTCMinutes() + 'm';

  useEffect(() => {
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_ROOT,
        data: { post_data },
      });
      var repos = axiosresponse.data;
      localStorage.setItem('staroverview', JSON.stringify(repos));
      var object = { value: 'value', timestamp: new Date().getTime() };
      localStorage.setItem('startiime', JSON.stringify(object));

      if (isMounted) {
        setStarData(repos);
        setLoading(false); //stop loading when data is fetched
      }
    };

    if (!storeStarTime) {
      var tmp = { value: 'value', timestamp: new Date().getTime() };
      localStorage.setItem('startiime', JSON.stringify(tmp));
      storeStarTime = localStorage.getItem('startiime');
    }
    var timestamp = JSON.parse(storeStarTime);
    const diff = (Date.now() - timestamp.timestamp) / 1000 / 60;

    if (!storeStarOverview || diff > 5) {
      var object = { value: 'value', timestamp: new Date().getTime() };
      localStorage.setItem('startiime', JSON.stringify(object));
      storeStarTime = localStorage.getItem('startiime');
      doFetch();
    } else {
      setStarData(JSON.parse(localStorage.getItem('staroverview')));
      setLoading(false); //stop loading when data is fetched
      isMounted = true;
    }
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  // if (stardata.length != 0) {
  //   console.log('stardata == ', stardata);
  // }
  const columns = useMemo(
    () => [
      {
        Header: 'Repository',
        accessor: 'Name',
        disableFilters: true,
        Cell: ({ cell: { value } }) => (
          <Link to={`/repos/${value}`}>{value}</Link> //style={{ color: 'beige' }}
        ),
      },
      {
        Header: HoursUTC,
        accessor: 'OneDay',
        disableFilters: true,
      },
      {
        Header: 'Last 7 Days',
        accessor: 'SevenDay',
        disableFilters: true,
      },
    ],
    []
  );
  return isLoading ? ( //Checkif if is loading
    <div>
      <h5>New Stargazers </h5>
      <div style={{ textAlign: 'center' }}>
        <p>Loading...</p>
        {props.Repolist.length > 20 && (
          <LinearWithValueLabel interval={props.Repolist.length * 15} />
        )}
        {props.Repolist.length <= 20 && <CircularProgress />}
      </div>
    </div>
  ) : (
    <div>
      <h5>New Stargazers </h5>
      <TableContainer
        columns={columns}
        data={stardata}
        darkmode={props.Darkmode}
      />
    </div>
  );
};

export default StarRepoTable;
