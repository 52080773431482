import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import repodetailsStyle from './repodetailsStyle.jsx';
import { ReChart, ReChartTotals } from '../../modules/ReactCharts/reCharts.jsx';
import axios from 'axios';
import APIServerRoot from '../../modules/UIComponents/ApiEnvironment.jsx';
import withStyles from '@mui/styles/withStyles';
import {
  CircularProgress,
  Link,
  Switch,
  FormGroup,
  FormControlLabel,
  Paper,
  Tooltip,
} from '@mui/material';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GitHubIcon from '@mui/icons-material/GitHub';
import RepoDetailsUserData from './RepoDetailsUserData.jsx';
import { differenceInCalendarDays, format, addDays, parseISO } from 'date-fns';
import RepoDetailsSummaryData from './RepoDetailsSummaryData.jsx';
// import RepoDetailsSummaryData from '../RepoDetails/RepoDetailsSummaryData.jsx';
import RepoDateContainer from './RepoDateContainer.jsx';
import RepoTopSeen from './RepoDetailsTopSeenData.jsx';
// import RepoTopSeen from '../RepoDetails/RepoDetailsTopSeenData.jsx';
import Container from '@mui/material/Container';
import {
  domainNegYAxis,
  domainPosYAxis,
} from '../../modules/ReactCharts/FindAxisMax.jsx';

import {
  REPODETAILS_PAGE_LOADED,
  REPODETAILS_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';
import { Seo } from '../../modules/Seo/Seo.jsx';

// consider this to implement when repo has not initialized
// https://codesandbox.io/s/so-react-useeffect-component-clean-up-rgxm0?file=/src/App.js

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: REPODETAILS_PAGE_LOADED }),
  onUnload: () => dispatch({ type: REPODETAILS_PAGE_UNLOADED }),
});

function RepoDetails(props) {
  const [rdata, setRepoDetailData] = useState([]); // for Repo Details information
  const [detaildate, setDetailDate] = useState([]);

  const [userfirstdatastars, setFirstDataStars] = useState([]);
  const [userlastdatastars, setLastDataStars] = useState([]);
  const [userlastdatasubs, setLastDataSubs] = useState([]);
  const [userfirstdatasubs, setFirstDataSubs] = useState([]);

  const [expanded, setExpanded] = useState('panel1');
  const [progressbar, setProgressBar] = useState(false);
  const [seendata, setSeendata] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), -30));
  const [normalize, setNormalize] = useState(false);
  const [chartDomain, setChartDomain] = useState([
    'auto', // Stars First Seen
    'auto', // Stars Last Seen
    'auto', // Subs First Seen
    'auto', // Subs Last Seen
    'auto', // Stars Total
    'auto', // Subs Total
  ]);

  const location = useLocation();
  const [isLoading, setLoading] = useState(true);

  var API_DETAILS = APIServerRoot() + '/search/getrepodetails';
  var API_SEEN = APIServerRoot() + '/search/getseencounts';

  let isMounted = false; // note mutable flag

  useEffect(() => {
    window.gtag('event', 'Page Read', {
      event_category: location.pathname + location.hash,
      event_label: location.pathname + location.hash,
    });

    setFirstDataStars([]);
    setLastDataStars([]);
    setLastDataSubs([]);
    setFirstDataSubs([]);
    setProgressBar(false);
    window.scrollTo(0, 0);

    props.onLoad();
  }, [location.hash]);

  var reponame = location.pathname;
  reponame = reponame.split('/repos/')[1];
  // reponame = reponame.substring(1);
  // console.log('reponame = ', reponame);
  useEffect(() => {
    // document.title = 'GitGenius - Repo ' + reponame;
    // document
    //   .querySelector('meta[name="description"]')
    //   .setAttribute('content', 'foobar');

    let abortController = new AbortController();
    isMounted = true; // note mutable flag
    var post_data = `[{"Name":"` + reponame.toLowerCase() + `"}]`;
    // console.log('post_data = ', post_data);
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      if (isMounted) {
        setRepoDetailData(axiosresponse.data);
      }
    };
    doFetch();
    return () => {
      // console.log('abort controller');
      abortController.abort();
    };
  }, [reponame]);

  useEffect(() => {
    let abortController = new AbortController();
    isMounted = true; // note mutable flag
    var post_data =
      `{"Name":"` +
      reponame +
      `", "Start":"` +
      format(startDate, 'yyyy-MM-dd') +
      `", "End":"` +
      format(endDate, 'yyyy-MM-dd') +
      `"}`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_SEEN,
        data: { post_data },
      });
      var data = axiosresponse.data;
      if (isMounted) {
        setLoading(false); //stop loading when data is fetched
        setSeendata(data);
        var starpos = domainPosYAxis(data.Stars, normalize) + 4; // normalize the
        var starneg = domainNegYAxis(data.Stars, normalize) - 4;
        var subspos = domainPosYAxis(data.Subs, normalize) + 4;
        var subsneg = domainNegYAxis(data.Subs, normalize) - 4;
        // console.log('star F, L = ', starpos, starneg);
        // console.log('sub F, L = ', subspos, subsneg);

        domainPosYAxis(data.Subs);
        setChartDomain([starpos, starneg, subspos, subsneg]);
        // console.log('ChartDomain = ', chartDomain);
      }
    };
    doFetch();

    return () => {
      abortController.abort();
    };
  }, [reponame, startDate, endDate, normalize]);

  if (isLoading == false && rdata != 'Not found' && rdata.created_at) {
    var daysTotal = differenceInCalendarDays(startDate, endDate);

    var daysFromCreate = differenceInCalendarDays(
      startDate,
      parseISO(rdata.created_at)
    );
  }
  const { classes } = props;
  const useDarkMode = useSelector((state) => state.common.useDarkMode);
  var tickColor;
  if (useDarkMode || useDarkMode == null) {
    tickColor = '#fff';
  } else {
    tickColor = '#000';
  }

  // var repodescription =
  //   'GitGenius - ' +
  //   rdata.full_name +
  //   ' Repo: ' +
  //   rdata.full_name;
  if (rdata == 'Not found') {
    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        <h2 className="text-xs-center">Repository Details</h2>
        <div style={{ textAlign: 'center' }}>
          {/* <p>Working to index Subscribers and Stargazers</p> */}
          <p> Not found or Still Initializing...</p>
          <CircularProgress />
        </div>
      </Container>
    );
  }
  const dataurl = 'https://github.com/' + rdata.full_name;
  var icon = '';
  // console.log('creation date - ', rdata.created_at);
  if (rdata.organization) {
    if (rdata.organization.avatar_url) {
      icon = rdata.organization.avatar_url;
    }
  }
  // console.log('organization = ', detaildata.organization);
  return isLoading ? ( //Checkif if is loading
    <Container
      sx={{
        paddingLeft: { lg: '340px' },
        paddingTop: '80px',
        minHeight: '100vh',
      }}
    >
      <Seo
        title={'GitGenius - Repo ' + rdata.full_name}
        name={rdata.full_name}
        desc={'Repo ' + rdata.full_name + ' - ' + rdata.description}
      />
      <div className={classes.separator}></div>
      <h2 className="text-xs-center">Repository Details</h2>
      <div style={{ textAlign: 'center' }}>
        <p>Loading...</p>
        <CircularProgress />
      </div>
    </Container>
  ) : (
    <Container
      maxWidth="false"
      sx={{
        paddingLeft: { lg: '340px' },
        paddingTop: '80px',
        minHeight: '100vh',
      }}
    >
      <Seo
        title={'GitGenius - Repo ' + rdata.full_name}
        name={rdata.full_name}
        desc={'Repo ' + rdata.full_name + ' - ' + rdata.description}
      />
      <h1 className="text-xs-center">
        <Tooltip title="Open on Github" placement="right">
          <Link href={dataurl} target="_blank">
            {rdata.full_name} <GitHubIcon fontSize="medium" />
          </Link>
        </Tooltip>
      </h1>
      <div style={{ height: '30px' }}>
        <div style={{ float: 'left' }}>
          <h2 className="text-xs-center">Repository Details</h2>
        </div>
        <div style={{ float: 'right', height: '60' }}>
          <img src={icon} style={{ width: 60, height: 60, float: 'right' }} />
        </div>
      </div>
      {/* <h3 className="text-xs-center">{detaildata.organization.login}</h3> */}
      <div className={classes.separator}></div>
      <div>
        <h3 style={{ fontSize: '1.15em', color: '#48A7F2' }}>
          {/* <span style={{ color: '#FD704B' }}>Description: </span> */}
          {rdata.description}
        </h3>
      </div>
      <RepoDetailsSummaryData Data={rdata} />
      <div className={classes.separator}></div>
      {RepoDateContainer(
        startDate,
        endDate,
        setEndDate,
        setStartDate,
        daysTotal,
        daysFromCreate,
        rdata
      )}
      <FormGroup style={{ float: 'left', width: '100%' }}>
        <FormControlLabel
          labelPlacement="end"
          control={
            <Switch
              // defaultChecked
              onChange={() => {
                setNormalize(!normalize);
              }}
            />
          }
          label="Normalize (Adjust Y access to reduce spikes in data)"
        />
      </FormGroup>
      <h5>Stargazers per day ({daysTotal} days)</h5>
      <ReChart
        SeenData={seendata.Stars}
        tickColor={tickColor}
        Height="400px"
        AxisF={chartDomain[0]}
        AxisL={chartDomain[1]}
        AxisT={chartDomain[4]}
        setDetailDate={setDetailDate}
      />
      <RepoDetailsUserData
        starorsub="stars"
        detaildate={detaildate}
        reponame={reponame}
        userfirstdata={userfirstdatastars}
        setUserFirst={setFirstDataStars}
        userlastdata={userlastdatastars}
        setUserLast={setLastDataStars}
        expanded={expanded}
        setExpanded={setExpanded}
        progressbar={progressbar}
        setProgressBar={setProgressBar}
        seendata={seendata}
      />
      <div className={classes.separator}></div>
      {props.currentUser != null ? (
        <div>
          <h5>Stargazer Totals per day ({daysTotal} days)</h5>
          <ReChartTotals
            SeenData={seendata.Stars}
            tickColor={tickColor}
            Height="200px"
            AxisT={chartDomain[4]}
            setDetailDate={setDetailDate}
          />
        </div>
      ) : (
        <div>
          <h5>Stargazer Totals per day ({daysTotal} days)</h5>

          <h6>Log in to view graph</h6>
          <Paper style={{ width: '100%', height: '100px' }} />
        </div>
      )}
      <div className={classes.separator}></div>
      <h5>Subscribers per day ({daysTotal} days)</h5>
      <ReChart
        SeenData={seendata.Subs}
        tickColor={tickColor}
        Height="400px"
        AxisF={chartDomain[2]}
        AxisL={chartDomain[3]}
        AxisT={chartDomain[5]}
        setDetailDate={setDetailDate}
      />
      <RepoDetailsUserData
        starorsub="subs"
        detaildate={detaildate}
        reponame={reponame}
        userfirstdata={userfirstdatasubs}
        setUserFirst={setFirstDataSubs}
        userlastdata={userlastdatasubs}
        setUserLast={setLastDataSubs}
        expanded={expanded}
        setExpanded={setExpanded}
        progressbar={progressbar}
        setProgressBar={setProgressBar}
        seendata={seendata}
      />
      <div className={classes.separator}></div>
      {props.currentUser != null ? (
        <div>
          <h5>Subscriber Totals per day ({daysTotal} days)</h5>
          <ReChartTotals
            SeenData={seendata.Subs}
            tickColor={tickColor}
            Height="200px"
            AxisT={chartDomain[4]}
            setDetailDate={setDetailDate}
          />
        </div>
      ) : (
        <div>
          <h5>Subscriber Totals per day ({daysTotal} days)</h5>
          <h6>Log in to view graph</h6>
          <Paper style={{ width: '100%', height: '100px' }} />
        </div>
      )}
      <div className={classes.separator}></div>
      <RepoTopSeen Reponame={reponame} />
    </Container>
  );
}

RepoDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(repodetailsStyle)(RepoDetails));
